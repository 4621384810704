import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableFilter'
})
export class TableFilterPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  transform(list: any[], value: string) {
    return value ? list.filter(item => item.received_Month === value || item.received_Year === value) : list;
 }

}
