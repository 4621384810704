import { Component, OnInit} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/shared/services/guards/auth.service';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { Auth, Amplify } from 'aws-amplify';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InformationComponent } from '@shared/modals/information/information.component';

import { request } from 'http';
import { environment } from '@environments/environment';

Amplify.configure({
  Auth_: {
    //identityPoolId: 'ap-southeast-1_OxfWFxzuX',
    region : 'ap-southeast-1',
    // dev -'6gbvhjbkn58j5scluj8p8999jk'
    // uat -'6u1nno2r4c049uq3mmalimnv7f'
    // prod - '7rgikb252bpacfa0nrplrjcrve'
    userPoolId: '6u1nno2r4c049uq3mmalimnv7f',
    
    userPoolWebClientId: 'hhtwebf47570b1_app_clientWeb',
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  }
});
@Component({
  selector: 'app-split-itinerary',
  templateUrl: './split-itinerary.component.html',
  styleUrls: ['./split-itinerary.component.scss']
})
export class SplitItineraryComponent implements OnInit {

  meters: Array<any> = [
    {
      id: 1,
      assignedGroup: '',
      meterNo: 'AJ2918301928',
      consumptionTypes: 'KH',
      count: 1
    },
    {
      id: 1,
      assignedGroup: '',
      meterNo: 'AJ2918301928',
      consumptionTypes: 'KH, DM',
      count: 2
    },
    {
      id: 1,
      assignedGroup: '',
      meterNo: 'AJ2918301928',
      consumptionTypes: 'KH',
      count: 1
    },
    {
      id: 1,
      assignedGroup: '',
      meterNo: 'AJ2918301928',
      consumptionTypes: 'KH, DM, RH',
      count: 3
    },
    {
      id: 1,
      assignedGroup: '',
      meterNo: 'AJ2918301928',
      consumptionTypes: 'KH',
      count: 1
    },
    {
      id: 1,
      assignedGroup: '',
      meterNo: 'AJ2918301928',
      consumptionTypes: 'KH, RH',
      count: 2
    },
    {
      id: 1,
      assignedGroup: '',
      meterNo: 'AJ2918301928',
      consumptionTypes: 'KH',
      count: 1
    },
    {
      id: 1,
      assignedGroup: '',
      meterNo: 'AJ2918301928',
      consumptionTypes: 'KH',
      count: 1
    },
    {
      id: 1,
      assignedGroup: '',
      meterNo: 'AJ2918301928',
      consumptionTypes: 'KH',
      count: 1
    },
    {
      id: 1,
      assignedGroup: '',
      meterNo: 'AJ2918301928',
      consumptionTypes: 'KH, DM, RH',
      count: 3
    },
    {
      id: 1,
      assignedGroup: '',
      meterNo: 'AJ2918301928',
      consumptionTypes: 'DM, RH',
      count: 2
    },
    {
      id: 1,
      assignedGroup: '',
      meterNo: 'AJ2918301928',
      consumptionTypes: 'KH',
      count: 1
    },
    {
      id: 1,
      assignedGroup: '',
      meterNo: 'AJ2918301928',
      consumptionTypes: 'KH',
      count: 1
    },
    {
      id: 1,
      assignedGroup: '',
      meterNo: 'AJ2918301928',
      consumptionTypes: 'KH',
      count: 1
    },
    {
      id: 1,
      assignedGroup: '',
      meterNo: 'AJ2918301928',
      consumptionTypes: 'KH, DM, RH',
      count: 3
    },
    {
      id: 1,
      assignedGroup: '',
      meterNo: 'AJ2918301928',
      consumptionTypes: 'KH',
      count: 1
    },
    {
      id: 1,
      assignedGroup: '',
      meterNo: 'AJ2918301928',
      consumptionTypes: 'KH',
      count: 1
    },
    {
      id: 1,
      assignedGroup: '',
      meterNo: 'AJ2918301928',
      consumptionTypes: 'KH, DM, RH',
      count: 3
    },
    {
      id: 1,
      assignedGroup: '',
      meterNo: 'AJ2918301928',
      consumptionTypes: 'KH',
      count: 1
    },
    {
      id: 1,
      assignedGroup: '',
      meterNo: 'AJ2918301928',
      consumptionTypes: 'KH, RH',
      count: 2
    },
  ];

  subItineraryGroup: Array<any> = ['A', 'B', 'C'];
  splitItineraryDetails: any = {
    itineraryCode: '',
    noOfSubItn: 0
  }

  isLoading: boolean = false;
  
  modalRef: NgbModalRef;
  unassigned: number = 0;
  assigned: number = 0;

  a: any = 0;
  b: any = 0;
  c: any = 0;

  actualA: any = 0;
  actualB: any = 0;
  actualC: any = 0;

  subItnFg: FormGroup;

  constructor(private router: Router, 
    private route: ActivatedRoute,
    private hhtApiService: HhtApiService,
    private authService: AuthService,
    private modal: NgbModal,
    private fb: FormBuilder
    ) { 
      this.subItnFg = this.fb.group({
        subItns: this.fb.array([])
      });

      this.route.params.subscribe({
        next: (result) => {
          if(!!result){
            this.splitItineraryDetails = result;
            this.prepSubItnFields();
          }
        },
        error: (error) =>{
          //error stack
        }
      })
    }
    

  ngOnInit() {
    this.loadMeters();
    this.refreshMeterCount();
  }

  prepSubItnFields(){
    const subItn = this.subItnFg.controls.subItns as FormArray;
    for(let i = 0; i < this.splitItineraryDetails.noOfSubItn; i++){
      console.log('iterate');
      subItn.push(this.fb.group({
        range: 0,
        totalPerCT: 0
      }));
    }
  }

  loadMeters(){
    this.hhtApiService.get_splitMeters().subscribe(
      {
        next: (result) => {
          console.log(result);
        },
        error: (stack) =>{

        }
      }
    );
  }

  searchMeters(modalContent){
    this.modalRef = this.modal.open(modalContent, {size: 'lg', scrollable: true, backdrop: 'static'});
  }

  refreshMeterCount(){
    let formArray = [this.a, this.b, this.c]; //representing FormArray

    //this should be a loop since we dynamically set a sub itn group, for now let's set it to this for demo purposes
    this.a = this.meters.reduce((pv, cv) => cv.assignedGroup == "A" ? ++pv : pv, 0); 
    this.b = this.meters.reduce((pv, cv) => cv.assignedGroup == "B" ? ++pv : pv, 0);
    this.c = this.meters.reduce((pv, cv) => cv.assignedGroup == "C" ? ++pv : pv, 0);

    this.actualA = this.meters.reduce((pv, cv) => cv.assignedGroup == "A" ? pv + cv.count : pv, 0);
    this.actualB = this.meters.reduce((pv, cv) => cv.assignedGroup == "B" ? pv + cv.count : pv, 0);
    this.actualC = this.meters.reduce((pv, cv) => cv.assignedGroup == "C" ? pv + cv.count : pv, 0);

    this.assigned = this.meters.reduce((pv, cv) => cv.assignedGroup != "" ? ++pv : pv, 0);
    this.unassigned = this.meters.reduce((pv, cv) => cv.assignedGroup == "" ? ++pv : pv, 0);
  }

  searchMetersSave(){
    this.refreshMeterCount();
  }

  autoAssign(){
    let meterArrayIndex = 0; //index for meters aray
    let formArray = [this.a, this.b, this.c]; //representing FormArray
    let valAsOf = 0; //will be used to cut to the next iteration of formArray
    for(let [index, val] of formArray.entries()){
      console.log(val);
      valAsOf += val;
      while(meterArrayIndex < this.meters.length && meterArrayIndex < valAsOf){
        this.meters[meterArrayIndex].assignedGroup = index == 0 ? 'A' : index == 1 ? 'B' : index == 2 ? 'C' : ''; //Please ask if possible to user numbers instead of A,B,C and so on to avoid this.
        meterArrayIndex += 1;
      }
    }
    this.refreshMeterCount();
  }
  
}
