import { Inject, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HhtApiService } from '@shared/services/hht-api.service';
import { AuthService } from '@shared/services/guards/auth.service';
import pdfMake from "pdfmake/build/pdfmake";  
import pdfFonts from "@assets/fonts/helvetica-vfs";  
pdfMake.vfs = pdfFonts.pdfMake.vfs;  

pdfMake.fonts = {
  HelveticaNeue: {
    normal: 'Helvetica65Medium_22443.woff',
    bold: 'HelveticaNeueBold.woff',
    italics: 'HelveticaNeueBlackItalic 1.woff',
    bolditalics: 'Helvetica 76 Bold Italic.woff'
  }
}

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  isGenerating: boolean = false;

  constructor(private datePipe: DatePipe,
              private hhtApiService: HhtApiService,
              private authService: AuthService) { 
                
              }

  getUser(): any{
    return this.authService.getCurrentUser();
  }

  printListingOfItineraries(params){
    this.isGenerating = true;
    this.hhtApiService.printListOfItineraries(params).subscribe(data =>{
      console.log(data['body']['itineraries'])
      let result = data['body']['itineraries'];
      let date = data['body']['date'];
      let time = data['body']['time'];
      let parent: Array<any> = [];
      result.forEach(itn=>{
        parent.push(
          {canvas: [{ type: 'line', x1: 0, y1: 5, x2: 794, y2: 5, lineWidth: 2 }]},
          { text: '\n' }, //newline
          {
            columns: [
              {
                text: `Reading Center:`,
                bold: true,
                width: 110
              },
              {
                text: ` ${itn['readingCenterCode']}`
              },
              {
                text: `Route:`,
                bold: true,
                width: 70
              },
              {
                text: `${itn['route']}`
              },
              {
                text: `Reader Code:`,
                bold: true,
                width: 170
              },
              {
                text: `${itn['readerCode']}`
              }
            ]
          },
          {
            columns: [
              {
                text: ``,
                bold: true,
                width: 110
              },
              {
                text: ``
              },
              {
                text: `Cycle:`,
                bold: true,
                width: 70
              },
              {
                text: `${itn['cycle']}`
              },
              {
                text: `Theoretical Reading Date:`,
                bold: true,
                width: 170
              },
              {
                text: `${this.datePipe.transform(itn['theoreticalReadDate'], 'M/d/yy') || ''}`
              }
            ]
          },
          {
            columns: [
              {
                text: ``,
                bold: true,
                width: 110
              },
              {
                text: ``
              },
              {
                text: `Itin.No.:`,
                bold: true,
                width: 70
              },
              {
                text: `${itn['itineraryNo']}`
              },
              {
                text: `Actual Reading Date:`,
                bold: true,
                width: 170
              },
              {
                text: `${this.datePipe.transform(itn['readDate'], 'M/d/yy') || ''}`
              }
            ]
          }
        );
        itn['lots'].forEach(lots=>{
          parent.push(
            {
              columns: [
                {
                  text: `Province:`,
                  bold: true,
                  width: 110
                },
                {
                  text: ` ${lots['province']}`
                }
              ]
            },
            {
              columns: [
                {
                  text: `Mun./City:`,
                  bold: true,
                  width: 110
                },
                {
                  text: ` ${lots['municipality']}`
                }
              ]
            },
            {
              columns: [
                {
                  text: `Barangay:`,
                  bold: true,
                  width: 110
                },
                {
                  text: ` ${lots['barangay']}`
                }
              ]
            },
            {
              columns: [
                {
                  text: `Subd./CMPD.:`,
                  bold: true,
                  width: 110
                },
                {
                  text: ` ${lots['subdivision']}`
                }
              ]
            },
            { text: '\n' }, //newline
            {canvas: [{ type: 'line', x1: 0, y1: 2, x2: 794, y2: 2, lineWidth: 2, dash: {length: 15, space: 0} }]},
            { text: '\n' }, //newline
            );
          let mtrs: Array<any> = [
            [
              {text: '', rowSpan: 2},
              {text: 'Customer Name', rowSpan: 2, alignment: 'center', bold: true},
              {text: 'Service', rowSpan: 2, alignment: 'center', bold: true},
              {text: 'Meter Number', rowSpan: 2, alignment: 'center', bold: true},
              {text: 'Meter Brand', rowSpan: 2, alignment: 'center', bold: true},
              {text: 'Cons. Type', rowSpan: 2, alignment: 'center', bold: true},
              {text: 'Reading', colSpan: 2, alignment: 'center', bold: true},
              {},
              {text: 'Field Finding', colSpan: 2, alignment: 'center', bold: true},
              {}
            ],
            [
              {},{},{},{},{},{},
              {text: 'Previous', alignment: 'center', bold: true},
              {text: 'Present', alignment: 'center', bold: true},
              {text: 'Code', alignment: 'center', bold: true},
              {text: 'Remarks', alignment: 'center', bold: true}
            ],
            [
              {text: 'Lot'},
              {text: lots['street']},
              {text: lots['houseNo']},
              {},{},{},{},{},{},{}
            ],
            [
              {text: 'Metering Pt.'},
              {},{},{},{},{},{},{},{},{}
            ]
          ];
          lots['meteringPoints'].forEach(mp=>{
            mp['meters'].forEach(meters=>{
              mtrs.push([
                {},
                {text: mp['clientName']},
                {},
                {text: meters['meterNo']},
                {text: meters['meterBrand']},
                {text: meters['consumptionType']},
                {text: meters['previousReading']},
                {text: meters['presentReading']},
                {text: meters['fieldFindingCode']},
                {text: meters['fieldFindingRemarks']}
              ])
            })
          })
          parent.push(
            {
              columns: [
                {width: '*', text: ''},
                {
                  table: {
                    headerRows: 2,
                    widths:['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                    body: mtrs
                  },
                  width: 'auto'
                },
                {width: '*', text: ''}
              ]
            },
            {//empty space new line
              text: '\n'
            }
          )
        })
      });

      let docDef = {
        pageOrientation: 'landscape',
        pageSize: 'LEGAL',
        pageMargins: [96, 96, 96, 96],
        defaultStyle: {
          fontSize: 12,
          font: 'HelveticaNeue'
        },
        header: (currentPage) => {
          return [
            {
              columns: [
                {
                  text: 'Meralco - Customer Management System',
                  alignment: 'center',
                  bold: true
                }
              ],
              marginTop: 30
            },
            {
              columns: [
                {
                  text: 'Listing of Itineraries for Manual Reading',
                  alignment: 'center',
                  bold: true
                }
              ]
            },
            {text: '\n'}, //new line
            {
              columns: [
                {
                  text: [{text: 'User:', bold: true}, `${this.getUser()['email']}`]
                },
                {
                  text: [{text: 'Date:', bold: true}, ` ${this.datePipe.transform(date, 'M/d/yy')}`]
                },
                {
                  text: [{text: 'Time:', bold: true}, ` ${time}`]
                },
                {
                  text: [{text: 'Page:', bold: true}, ` ${currentPage}`]
                },
              ],
              marginLeft: 96
            },
            {canvas: [{ type: 'line', x1: 96, y1: 5, x2: 890, y2: 5, lineWidth: 2 }]}
          ]
        },
        content: parent
      }

      pdfMake.createPdf(docDef).open();
      this.isGenerating = false;
    })
  }

  printAccomplishmentReports(params){
    this.isGenerating = true;
    let parent: Array<any> = [];
    this.hhtApiService.printAccomplishmentReports(params).subscribe(data =>{
      let result = data['body']['results'];
      let date = data['body']['date'];
      let time = data['body']['time'];
      console.log(result);
      result.forEach(itn => {
        parent.push(
        {canvas: [{ type: 'line', x1: 0, y1: 5, x2: 815, y2: 5, lineWidth: 2 }]},
        {text: '\n'}, //new line
        {
          columns: [
            {text: 'Reading Center:', bold: true},
            {text: `${itn['readingCenterCode']}`},
            {text: 'Theoretical Rdg Date:', bold: true},
            {text: `${this.datePipe.transform(itn['theoreticalReadDate'], 'M/d/yy') || ''}`}
          ]
        },
        {
          columns: [
            {text: 'Route:', bold: true},
            {text: `${itn['route']}`},
            {text: 'Actual Reading Date:', bold: true},
            {text: `${this.datePipe.transform(itn['readDate'], 'M/d/yy') || ''}`}
          ]
        },
        {
          columns: [
            {text: 'Cycle:', bold: true},
            {text: `${itn['cycle']}`},
            {text: 'Reader Code:', bold: true},
            {text: `${itn['readerCode']}`}
          ]
        },
        {
          columns: [
            {text: 'Itinerary Number:', bold: true},
            {text: `${itn['itineraryNo']}`},
            {text: 'HHT Code:', bold: true},
            {text: `${itn['device_code']}`}
          ]
        },
        {text: '\n'}, //newline
        {canvas: [{ type: 'line', x1: 0, y1: 2, x2: 815, y2: 2, lineWidth: 2, dash: {length: 15, space: 0} }]},
        {text: '\n'}
        );
        let mtrs: Array<any> = [
          [
            {text: 'Lot Address', alignment: 'center', bold: true},
            {text: 'Client Name', alignment: 'center', bold: true},
            {text: 'Meter No.', alignment: 'center', bold: true},
            {text: 'Type', alignment: 'center', bold: true},
            {text: 'Previous Rdg.', alignment: 'center', bold: true},
            {text: 'Rdg.', alignment: 'center', bold: true},
            {text: 'Time', alignment: 'center', bold: true},
            {text: 'RT', alignment: 'center', bold: true},
            {text: 'Mtr FF/Remark', alignment: 'center', bold: true, colSpan: 3},
            {},
            {}
          ]
        ];
        itn['lots'].forEach(lots =>{
          let mpFirstIndex = 0;
          lots['meteringPoints'].forEach(mp =>{
            let meterFirstIndex = 0;
              mp['meters'].forEach(meter =>{
                mtrs.push(
                  [
                    {text: mpFirstIndex == 0 ? `${lots['houseNo']} ${lots['street']}` : ''},
                    {text: meterFirstIndex == 0 ? `${mp['clientName']}` : ''},
                    {text: `${meter['meterNo']}`},
                    {text: `${meter['consumptionType']}`},
                    {text: `${meter['previousReading']}`},
                    {text: `${meter['presentReading']}`},
                    {text: `${meter['readTime']}`},
                    {text: `${meter['RT']}`},
                    {text: `${meter['fieldFindingCode']} - ${meter['fieldFindingRemarks']}`},
                    {text: `${meter['fieldFindingCode_2']} - ${meter['fieldFindingRemarks_2']}`},
                    {text: `${meter['fieldFindingCode_3']} - ${meter['fieldFindingRemarks_3']}`}
                  ]
                )
                mpFirstIndex++;
                meterFirstIndex++;
              })
          })
        });
        parent.push(
          {
            columns: [
              {width: '*', text: ''},
              {
                table: {
                  headerRows: 1,
                  widths:['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                  body: mtrs
                },
                width: 'auto'
              },
              {width: '*', text: ''}
            ]
          })
      })
      let docDef = {
        pageOrientation: 'landscape',
        pageSize: 'LEGAL',
        pageMargins: [96, 96, 96, 96],
        defaultStyle: {
          fontSize: 12,
          font: 'HelveticaNeue'
        },
        header: (currentPage) => {
          return [
            {
              columns: [
                {
                  text: 'Meralco - Customer Management System',
                  alignment: 'center',
                  bold: true
                }
              ],
              marginTop: 30
            },
            {
              columns: [
                {
                  text: 'Listing on Accomplished Readings',
                  alignment: 'center',
                  bold: true
                }
              ]
            },
            {text: '\n'}, //new line
            {
              columns: [
                {text: 'Date:', bold: true},
                {text: `${this.datePipe.transform(date, 'M/d/yy')}`},
                {text: 'Time:', bold: true},
                {text: `${time}`},
                {text: 'Page:', bold: true},
                {text: `${currentPage}`}
              ],
              marginLeft: 96
            },
            {canvas: [{ type: 'line', x1: 96, y1: 5, x2: 911, y2: 5, lineWidth: 2 }]}
          ]
        },
        content: parent
      }
      
      pdfMake.createPdf(docDef).open();
      this.isGenerating = false;
    })
  }

  printSummaryAccomplishmentReports(params){
    this.isGenerating = true;
    let parent: Array<any> = [];
    this.hhtApiService.printSummaryAccomplishmentReports(params).subscribe(data =>{
      let result = data['body']['results'];
      let date = data['body']['date'];
      let time = data['body']['time'];
      console.log(result);
      result.forEach(itn => {
        parent.push(
        {canvas: [{ type: 'line', x1: 0, y1: 5, x2: 815, y2: 5, lineWidth: 2 }]},
        {text: '\n'}, //new line
        {
          columns: [
            {text: 'Reading Center:', bold: true},
            {text: `${itn['readingCenterCode']}`},
            {text: 'Theoretical Rdg Date:', bold: true},
            {text: `${this.datePipe.transform(itn['theoreticalReadDate'], 'M/d/yy') || ''}`}
          ]
        },
        {
          columns: [
            {text: 'Route:', bold: true},
            {text: `${itn['route']}`},
            {text: 'Actual Reading Date:', bold: true},
            {text: `${this.datePipe.transform(itn['readDate'], 'M/d/yy') || ''}`}
          ]
        },
        {
          columns: [
            {text: 'Cycle:', bold: true},
            {text: `${itn['cycle']}`},
            {text: 'Reader Code:', bold: true},
            {text: `${itn['readerCode']}`}
          ]
        },
        {
          columns: [
            {text: 'Itinerary Number:', bold: true},
            {text: `${itn['itineraryNo']}`},
            {text: 'HHT Code:', bold: true},
            {text: `${itn['device_code']}`}
          ]
        },
        {text: '\n'}, //newline
        {canvas: [{ type: 'line', x1: 0, y1: 2, x2: 815, y2: 2, lineWidth: 2, dash: {length: 15, space: 0} }]},
        {text: '\n'}
        );
        let mtrs: Array<any> = [
          [
            {text: 'Lot Address', alignment: 'center', bold: true},
            {text: 'Client Name', alignment: 'center', bold: true},
            {text: 'Meter No.', alignment: 'center', bold: true},
            {text: 'Type', alignment: 'center', bold: true},
            {text: 'Previous Rdg.', alignment: 'center', bold: true},
            {text: 'Rdg.', alignment: 'center', bold: true},
            {text: 'Time', alignment: 'center', bold: true},
            {text: 'RT', alignment: 'center', bold: true},
            {text: 'Mtr FF/Remark', alignment: 'center', bold: true, colSpan: 3},
            {},
            {}
          ]
        ];
        itn['lots'].forEach(lots =>{
          let mpFirstIndex = 0;
          lots['meteringPoints'].forEach(mp =>{
            let meterFirstIndex = 0;
              mp['meters'].forEach(meter =>{
                mtrs.push(
                  [
                    {text: mpFirstIndex == 0 ? `${lots['houseNo']} ${lots['street']}` : ''},
                    {text: meterFirstIndex == 0 ? `${mp['clientName']}` : ''},
                    {text: `${meter['meterNo']}`},
                    {text: `${meter['consumptionType']}`},
                    {text: `${meter['previousReading']}`},
                    {text: `${meter['presentReading']}`},
                    {text: `${meter['readTime']}`},
                    {text: `${meter['RT']}`},
                    {text: `${meter['fieldFindingCode']} - ${meter['fieldFindingRemarks']}`},
                    {text: `${meter['fieldFindingCode_2']} - ${meter['fieldFindingRemarks_2']}`},
                    {text: `${meter['fieldFindingCode_3']} - ${meter['fieldFindingRemarks_3']}`}
                  ]
                )
                mpFirstIndex++;
                meterFirstIndex++;
              })
          })
        });
        parent.push(
          {
            columns: [
              {width: '*', text: ''},
              {
                table: {
                  headerRows: 1,
                  widths:['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                  body: mtrs
                },
                width: 'auto'
              },
              {width: '*', text: ''}
            ]
          })
      })
      let docDef = {
        pageOrientation: 'landscape',
        pageSize: 'LEGAL',
        pageMargins: [96, 96, 96, 96],
        defaultStyle: {
          fontSize: 12,
          font: 'HelveticaNeue'
        },
        header: (currentPage) => {
          return [
            {
              columns: [
                {
                  text: 'Meralco - Customer Management System',
                  alignment: 'center',
                  bold: true
                }
              ],
              marginTop: 30
            },
            {
              columns: [
                {
                  text: 'Listing on Accomplished Readings',
                  alignment: 'center',
                  bold: true
                }
              ]
            },
            {text: '\n'}, //new line
            {
              columns: [
                {text: 'Date:', bold: true},
                {text: `${this.datePipe.transform(date, 'M/d/yy')}`},
                {text: 'Time:', bold: true},
                {text: `${time}`},
                {text: 'Page:', bold: true},
                {text: `${currentPage}`}
              ],
              marginLeft: 96
            },
            {canvas: [{ type: 'line', x1: 96, y1: 5, x2: 911, y2: 5, lineWidth: 2 }]}
          ]
        },
        content: parent
      }
      
      pdfMake.createPdf(docDef).open();
      this.isGenerating = false;
    })
  }

  printItineraryRemarksReport(params){
    this.isGenerating = true;
    this.hhtApiService.printItineraryRemarksReport(params).subscribe(data =>{
      console.log(data['body'])
      let result = data['body']['results'];
      let date = data['body']['date'];
      let time = data['body']['time'];
      let rmks: Array<any> = [];
      rmks.push([
        {text: 'Remark Number', alignment: 'center', bold: true},
        {text: 'Itinerary Remarks', alignment: 'center', bold: true}
      ])
      result.forEach(remarks => {
        rmks.push([
          {text: `${remarks['remark_number']}`},
          {text: `${remarks['remarks'] || ''}`}
        ])
      });

      let parent: Array<any> = [
        {
          columns: [
            {width: '*', text: ''},
            {
              table: {
                headerRows: 1,
                widths:['auto', 'auto'],
                body: rmks
              },
              width: 'auto'
            },
            {width: '*', text: ''}
          ]
        }
      ];

      let docDef = {
        pageOrientation: 'landscape',
        pageSize: 'LEGAL',
        pageMargins: [96, 96, 96, 96],
        defaultStyle: {
          fontSize: 12,
          font: 'HelveticaNeue'
        },
        header: (currentPage) => {
          return [
            {
              columns: [
                {
                  text: 'Meralco - Customer Management System',
                  alignment: 'center',
                  bold: true
                }
              ],
              marginTop: 30
            },
            {
              columns: [
                {
                  text: 'Itinerary Remarks Report',
                  alignment: 'center',
                  bold: true
                }
              ]
            },
            {text: '\n'}, //new line
            {
              columns: [
                {
                  text: [{text: 'User:', bold: true}, ` ${this.getUser()['email']}`],
                  width: 300
                },
                {text: 'Date:', bold: true},
                {text: `${this.datePipe.transform(date, 'M/d/yy')}`},
                {text: 'Time:', bold: true},
                {text: `${time}`},
                {text: 'Page:', bold: true},
                {text: `${currentPage}`}
              ],
              marginLeft: 96
            },
            {canvas: [{ type: 'line', x1: 96, y1: 5, x2: 911, y2: 5, lineWidth: 2 }]}
          ]
        },
        content: parent
      }

      pdfMake.createPdf(docDef).open();
      this.isGenerating = false;
    })
  }

  printPreviousAndOrCurrentAcceptedItineraries(params) {
    this.hhtApiService.previouslyAndOrCurrentlyAcceptedItinerariesReport(params).subscribe(data =>{
      console.log(data['body'])
      let result = data['body']['results'];
      const date = data['body']['date'];
      const time = data['body']['time'];
      let tableBody: Array<any> = [];
      tableBody.push([
        {text: 'Itinerary Number', alignment: 'center', bold: true},
        {text: 'Readings', alignment: 'center', bold: true},
        {text: 'Size', alignment: 'center', bold: true},
        {text: 'Diff Level', alignment: 'center', bold: true},
        {text: 'Assigned To', alignment: 'center', bold: true},
        {text: 'Theor. Rdg. Date', alignment: 'center', bold: true},
        {text: 'Actual Rdg. Date', alignment: 'center', bold: true},
        {text: 'Status', alignment: 'center', bold: true},
      ])
      result.forEach(itn => {
        tableBody.push([
          {text: `${itn['itineraryCode']}`},
          {text: `${itn['readings']}`},
          {text: `${itn['size']}`},
          {text: `${itn['difficultyLevel']}`},
          {text: `${itn['assigned_to'] || ''}`},
          {text: `${this.datePipe.transform(itn['theoreticalReadDate'], 'M/d/yy') || ''}`},
          {text: `${this.datePipe.transform(itn['readDate'], 'M/d/yy') || ''}`},
          {text: `${itn['status']}`},
        ])
      });

      let parent: Array<any> = [
        {
          columns: [
            {width: '*', text: ''},
            {
              table: {
                headerRows: 1,
                widths:['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                body: tableBody
              },
              width: 'auto'
            },
            {width: '*', text: ''}
          ]
        }
      ];

      let docDef = {
        pageOrientation: 'landscape',
        pageSize: 'LEGAL',
        pageMargins: [96, 130, 96, 96],
        defaultStyle: {
          fontSize: 12,
          font: 'HelveticaNeue'
        },
        header: (currentPage) => {
          return [
            {
              columns: [
                {
                  text: 'Meralco - Customer Management System',
                  alignment: 'center',
                  bold: true
                }
              ],
              marginTop: 30
            },
            {
              columns: [
                {
                  text: 'Previously and/or Currently Accepted Itineraries',
                  alignment: 'center',
                  bold: true
                }
              ]
            },
            {text: '\n'}, //new line
            {
              columns: [
                {
                  text: [{text: 'User:', bold: true}, `${this.getUser()['email']}`],
                  width: 'auto'
                },
                {
                  text: '',
                  width: '*'
                },
                {
                  text: [{text: 'Page:', bold: true}, `             ${currentPage}`],
                  width: 'auto'
                }
              ],
              marginLeft: 96,
              marginRight: 96
            },
            {
              columns: [
                {
                  text: [{text: 'Reading Center: ', bold: true}, `${params.readingCenterCode}`],
                  width: 'auto'
                },
                {
                  text: '',
                  width: '*'
                },
                {
                  text: [{text: 'Date:', bold: true}, `   ${this.datePipe.transform(date, 'M/d/yy')}`],
                  width: 'auto'
                }
              ],
              marginLeft: 96,
              marginRight: 96
            },
            {
              columns: [
                {
                  text: '',
                  width: '*'
                },
                {
                  text: [{text: 'Time:', bold: true}, ` ${time}`],
                  width: 'auto'
                }
              ],
              marginLeft: 96,
              marginRight: 96
            },
            {canvas: [{ type: 'line', x1: 96, y1: 5, x2: 910, y2: 5, lineWidth: 2 }]}
          ]
        },
        content: parent
      }

      pdfMake.createPdf(docDef).open();
      this.isGenerating = false;
    })
  }

  printDailyAssignmentOfItineraries(params){
    this.isGenerating = true;
    this.hhtApiService.dailyAssignmentOfItinerariesReport(params).subscribe(data =>{
      console.log(data['body']['results'])
      let result = data['body']['results'];
      let date = data['body']['date'];
      let time = data['body']['time'];
      let tableBody: Array<any> = [];
      tableBody.push([
        {text: 'R/C', alignment: 'center', bold: true},
        {text: 'Route', alignment: 'center', bold: true},
        {text: 'Cycle', alignment: 'center', bold: true},
        {text: 'Itin.No.', alignment: 'center', bold: true},
        {text: 'Support', alignment: 'center', bold: true},
        {text: 'Theor. Rdg. Date', alignment: 'center', bold: true},
        {text: 'Reader', alignment: 'center', bold: true},
        {text: 'Date Received', alignment: 'center', bold: true},
        {text: 'Signature', alignment: 'center', bold: true},
        {text: 'Date Returned', alignment: 'center', bold: true},
        {text: 'Status', alignment: 'center', bold: true},
        {text: 'Signature', alignment: 'center', bold: true}
      ])
      result.forEach(itn => {
        tableBody.push([
          {text: `${itn['readingCenterCode']}`},
          {text: `${itn['route']}`},
          {text: `${itn['cycle']}`},
          {text: `${itn['itineraryNo']}`},
          {text: `${itn['support']}`}, 
          {text: `${itn['theoreticalReadDate']}`},
          {text: `${itn['readerCode'] || ''}`},
          {text: `${this.datePipe.transform(itn['date_received'], 'M/d/yy') || ''}`}, 
          {text: `${itn['received_signature']}`},
          {text: `${this.datePipe.transform(itn['date_returned'], 'M/d/yy') || ''}`}, 
          {text: `${itn['status']}`},
          {text: `${itn['returned_signature']}`}
        ])
      });
      let parent: Array<any> = [
        {
          columns: [
            {width: '*', text: ''},
            {
              table: {
                headerRows: 1,
                widths:['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                body: tableBody
              },
              width: 'auto'
            },
            {width: '*', text: ''}
          ]
        }
      ];

      let docDef = {
        pageOrientation: 'landscape',
        pageSize: 'LEGAL',
        pageMargins: [96, 130, 96, 96],
        defaultStyle: {
          fontSize: 12,
          font: 'HelveticaNeue'
        },
        header: (currentPage) => {
          return [
            {
              columns: [
                {
                  text: 'Meralco - Customer Management System',
                  alignment: 'center',
                  bold: true
                }
              ],
              marginTop: 30
            },
            {
              columns: [
                {
                  text: 'Daily Assignment of Itineraries to be read',
                  alignment: 'center',
                  bold: true
                }
              ]
            },
            {text: '\n'}, //new line
            {
              columns: [
                {
                  text: [{text: 'User:', bold: true}, `${this.getUser()['email']}`],
                  width: 'auto'
                },
                {
                  text: '',
                  width: '*'
                },
                {
                  text: [{text: 'Page:', bold: true}, `             ${currentPage}`],
                  width: 'auto'
                }
              ],
              marginLeft: 96,
              marginRight: 96
            },
            {
              columns: [
                {
                  text: [{text: 'Reading Center: ', bold: true}, `${params.readingCenterCode}`],
                  width: 'auto'
                },
                {
                  text: '',
                  width: '*'
                },
                {
                  text: [{text: 'Date:', bold: true}, `   ${this.datePipe.transform(date, 'M/d/yy')}`],
                  width: 'auto'
                }
              ],
              marginLeft: 96,
              marginRight: 96
            },
            {
              columns: [
                {
                  text: '',
                  width: '*'
                },
                {
                  text: [{text: 'Time:', bold: true}, ` ${time}`],
                  width: 'auto'
                }
              ],
              marginLeft: 96,
              marginRight: 96
            },
            {canvas: [{ type: 'line', x1: 96, y1: 5, x2: 910, y2: 5, lineWidth: 2 }]}
          ]
        },
        content: parent
      }

      pdfMake.createPdf(docDef).open();
      this.isGenerating = false;
    })
  }

  printMetersWithFieldFindings(params){
    this.isGenerating = true;
    this.hhtApiService.printMetersWithFieldFindingsReports(params).subscribe(data =>{
      console.log('printMetersWithFieldFindingsReports')
      console.log(data['body']['results'])
      let results = data['body']['results'];
      const date = data['body']['date'];
      const time = data['body']['time'];
      let parent: Array<any> = [];
      const table: Array<any> = [];
      if (results.length > 0) {
        this.hhtApiService.getReadingCenters(results[0]['readingCenterCode']).subscribe(readingCenterData =>{
          const readingCenterResults = readingCenterData['body']['results'];
          if (readingCenterResults.length === 1) {
            console.log(readingCenterResults)
            const readingCenter = readingCenterResults[0].description;
            table.push(
              [
                {text: 'Date handled', alignment: 'center', bold: true},
                {text: 'Route', alignment: 'center', bold: true},
                {text: 'Itin No.', alignment: 'center', bold: true},
                {text: 'Meter Number', alignment: 'center', bold: true},
                {text: 'L.I.N.', alignment: 'center', bold: true},
                {text: 'Address', alignment: 'center', bold: true},
                {text: 'FF', alignment: 'center', bold: true},
                {text: 'FF_2', alignment: 'center', bold: true},
                {text: 'FF_3', alignment: 'center', bold: true},
                {text: 'Description', alignment: 'center', bold: true},
                {text: 'Description_2', alignment: 'center', bold: true},
                {text: 'Description_3', alignment: 'center', bold: true},
                {text: 'Remarks', alignment: 'center', bold: true},
                {text: 'Rdg', alignment: 'center', bold: true},
              ]
            );
            results.forEach(result=>{
              table.push(
                [
                  {text: this.datePipe.transform(result['date_handled'], 'M/d/yy') || ''},
                  {text: result['route']},
                  {text: result['itineraryNo']},
                  {text: result['meterNo']},
                  {text: result['l_i_n']},
                  {text: result['street']},
                  {text: result['fieldFindingCode']},
                  {text: result['fieldFindingCode_2']},
                  {text: result['fieldFindingCode_3']},
                  {text: result['description']},
                  {text: result['description_2']},
                  {text: result['description_3']},
                  {text: result['remarks']},
                  {text: result['readings']},
                ]
              );
            });
      
            parent.push(
              { text: '\n' }, //newline
              {
                columns: [
                  {width: '*', text: ''},
                  {
                    table: {
                      headerRows: 1,
                      widths:['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                      body: table
                    },
                    width: 'auto',
                    layout: 'headerLineOnly'
                  },
                  {width: '*', text: ''}
                ]
              }
            );
      
            let docDef = {
              pageOrientation: 'landscape',
              pageSize: 'LEGAL',
              pageMargins: [96, 96, 96, 96],
              defaultStyle: {
                fontSize: 12,
                font: 'HelveticaNeue'
              },
              header: (currentPage) => {
                return [
                  {
                    columns: [
                      {
                        text: 'Meralco - Customer Management System',
                        alignment: 'center',
                        bold: true
                      }
                    ],
                    marginTop: 30
                  },
                  {
                    columns: [
                      {
                        text: 'Meter Reading Field Findings Report',
                        alignment: 'center',
                        bold: true
                      }
                    ]
                  },
                  {text: '\n'}, //new line
                  {
                    columns: [
                      {
                        text: [{text: 'Reading Center:', bold: true}, ` ${readingCenter}`]
                      },
                      {
                        text: [{text: 'Date:', bold: true}, ` ${this.datePipe.transform(date, 'M/d/yy')}`]
                      },
                      {
                        text: [{text: 'Time:', bold: true}, ` ${time}`]
                      },
                      {
                        text: [{text: 'Page:', bold: true}, ` ${currentPage}`]
                      },
                    ],
                    marginLeft: 96
                  },
                  {canvas: [{ type: 'line', x1: 96, y1: 5, x2: 970, y2: 5, lineWidth: 2 }]}
                ]
              },
              content: parent
            }
      
            pdfMake.createPdf(docDef).open();
            this.isGenerating = false;
          }
        })
      }
    })
  }

  printRemovedAndInstalledMeterSeals(params){
    this.isGenerating = true;
    this.hhtApiService.printRemovedAndInstalledMeterSealsReports(params).subscribe(data =>{
      console.log(data['body']['results'])
      let results = data['body']['results'];
      const date = data['body']['date'];
      const time = data['body']['time'];
      let parent: Array<any> = [];
      let table: Array<any> = [];
      results.forEach(result => {
        parent.push(
          {canvas: [{ type: 'line', x1: 0, y1: 5, x2: 794, y2: 5, lineWidth: 2 }]},
          { text: '\n' }, //newline
          {
            columns: [
              {
                text: `Reading Center:`,
                bold: true,
                width: 110
              },
              {
                text: ` ${result['readingCenterCode']}`
              },
              {
                text: `Theoretical Rdg Date:`,
                bold: true,
                width: 145
              },
              {
                text: ` ${this.datePipe.transform(result['theoreticalReadDate'], 'M/d/yy') || ''}`
              }
            ]
          },
          { text: '\n' }, //newline
          {
            columns: [
              {
                text: `Route:`,
                bold: true,
                width: 110
              },
              {
                text: ` ${result['route']}`
              },
              {
                text: `Actual Rdg Date:`,
                bold: true,
                width: 145
              },
              {
                text: ` ${this.datePipe.transform(result['readDate'], 'M/d/yy') || ''}`
              }
            ]
          },
          {
            columns: [
              {
                text: `Cycle:`,
                bold: true,
                width: 110
              },
              {
                text: ` ${result['cycle']}`
              },
              {
                text: `Reader Code:`,
                bold: true,
                width: 145
              },
              {
                text: ` ${result['readerCode']}`
              }
            ]
          },
          {
            columns: [
              {
                text: `Itinerary Number:`,
                bold: true,
                width: 110
              },
              {
                text: ` ${result['itineraryNo']}`
              },
              {
                text: `HHT Code:`,
                bold: true,
                width: 145
              },
              {
                text: ` ${result['device_code']}`
              }
            ]
          },
          { text: '\n' }, //newline
        );
        table.push(
          [
            {text: 'Item No.', alignment: 'center', bold: true},
            {text: 'Installed Seal', alignment: 'center', bold: true},
            {text: 'Removed Seal', alignment: 'center', bold: true},
            {text: 'Meter Number', alignment: 'center', bold: true},
            {text: 'Lot ID No.', alignment: 'center', bold: true},
          ]
        );
        result['lots'].forEach(lot => {
          console.log('waow');
          console.log(parent);
          lot['meteringPoints'].forEach(meteringPoint => {
            meteringPoint['meters'].forEach(meter => {
              if (meter['meterSeal'].length > 0) {
                
                meter['meterSeal'].forEach( seal => {
                  table.push(
                    [
                      {text: seal['item_no']},
                      {text: seal['installed']},
                      {text: seal['removed']},
                      {text: meter['meterNo']},
                      {text: lot['lot_id_number']},
                    ]
                  );
                })
              }
            })
          })
        })
        parent.push(
          { text: '\n' }, //newline
          {
            columns: [
              {width: '*', text: ''},
              {
                table: {
                  headerRows: 1,
                  widths:['auto', 'auto', 'auto', 'auto', 'auto'],
                  body: table
                },
                width: 'auto'
              },
              {width: '*', text: ''}
            ]
          }
        );
      })

      let docDef = {
        pageOrientation: 'landscape',
        pageSize: 'LEGAL',
        pageMargins: [96, 96, 96, 96],
        defaultStyle: {
          fontSize: 12,
          font: 'HelveticaNeue'
        },
        header: (currentPage) => {
          return [
            {
              columns: [
                {
                  text: 'Meralco - Customer Management System',
                  alignment: 'center',
                  bold: true
                }
              ],
              marginTop: 30
            },
            {
              columns: [
                {
                  text: 'Meter Seal Report',
                  alignment: 'center',
                  bold: true
                }
              ]
            },
            {text: '\n'}, //new line
            {
              columns: [
                {
                  text: [{text: 'User:', bold: true}, ` ${this.getUser()['email']}`]
                },
                {
                  text: [{text: 'Date:', bold: true}, ` ${this.datePipe.transform(date, 'M/d/yy')}`]
                },
                {
                  text: [{text: 'Time:', bold: true}, ` ${time}`]
                },
                {
                  text: [{text: 'Page:', bold: true}, ` ${currentPage}`]
                },
              ],
              marginLeft: 96
            },
            {canvas: [{ type: 'line', x1: 96, y1: 5, x2: 890, y2: 5, lineWidth: 2 }]}
          ]
        },
        content: parent
      }

      pdfMake.createPdf(docDef).open();
      this.isGenerating = false;
    })
  }

  printHandlingOfItineraries(params){
    this.isGenerating = true;
    let readingCenter = params.readingCenterCode;
    this.hhtApiService.getReadingCenters(params.readingCenterCode).subscribe(readingCenterData =>{
      const readingCenterResults = readingCenterData['body']['results'];
      if (readingCenterResults.length === 1) {
        readingCenter = readingCenterResults[0].description;
      }
      this.hhtApiService.printHandlingOfItinerariesReports(params).subscribe(data =>{
        console.log(data['body']['results'])
        let results = data['body']['results'];
        const total = data['body']['total'];
        const date = data['body']['date'];
        const time = data['body']['time'];
        let parent: Array<any> = [];
        const table: Array<any> = [];
        table.push(
          [
            {text: '', colSpan: 3},
            {},
            {},
            {text: 'Date of Reading', rowSpan: 2, alignment: 'center', bold: true},
            {text: 'Number of Readings', colSpan: 8, alignment: 'center', bold: true},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {text: 'Diff. Lev.', colSpan: 2, alignment: 'center', bold: true},
            {},
            {text: 'Quality of Reading', colSpan: 8, alignment: 'center', bold: true},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
          ],
          [
            {text: 'RR', alignment: 'center', bold: true},
            {text: 'CC', alignment: 'center', bold: true},
            {text: 'Itin', alignment: 'center', bold: true},
            {},
            {text: 'Reader', alignment: 'center', bold: true},
            {text: 'HHT', alignment: 'center', bold: true},
            {text: 'Loaded', alignment: 'center', bold: true},
            {text: 'Visited', alignment: 'center', bold: true},
            {text: 'Actual', alignment: 'center', bold: true},
            {text: 'Absent', alignment: 'center', bold: true},
            {text: 'W/F.F.', alignment: 'center', bold: true},
            {text: 'Forced', alignment: 'center', bold: true},
            {text: 'Theor', alignment: 'center', bold: true},
            {text: 'Actual', alignment: 'center', bold: true},
            {text: 'Visited', alignment: 'center', bold: true},
            {text: 'Actual', alignment: 'center', bold: true},
            {text: 'Absent', alignment: 'center', bold: true},
            {text: 'W/F.F.', alignment: 'center', bold: true},
            {text: 'Forced', alignment: 'center', bold: true},
            {text: 'Action', alignment: 'center', bold: true},
            {text: 'Start', alignment: 'center', bold: true},
            {text: 'End', alignment: 'center', bold: true},
          ]
        );

        results.forEach(result => {
          table.push(
            [
              {text: result['route']},
              {text: result['cycle']},
              {text: result['itineraryNo']},
              {text: this.datePipe.transform(result['readDate'], 'M/d/yy') || ''},
              {text: result['readingCenterCode']},
              {text: result['hht']},
              {text: result['loaded']},
              {text: result['visited']},
              {text: result['actual']},
              {text: result['absent']},
              {text: result['wff']},
              {text: result['forced']},
              {text: result['theo_diff_level']},
              {text: result['actual_diff_level']},
              {text: result['q.visited']},
              {text: result['q.actual']},
              {text: result['q.absent']},
              {text: result['q.wff']},
              {text: result['q.forced']},
              {text: result['action']},
              {text: this.datePipe.transform(result['read_start_time'], 'h:mm:ss')},
              {text: this.datePipe.transform(result['read_end_time'], 'h:mm:ss')}
            ]
          );
        })

        table.push(
          [
            {text: '', colSpan: 4},
            {},
            {},
            {},
            {text: 'Total'},
            {text: '...'},
            {text: total['loaded']},
            {text: total['visited']},
            {text: total['actual']},
            {text: total['absent']},
            {text: total['wff']},
            {text: total['forced']},
            {text: total['theo_diff_level']},
            {text: total['actual_diff_level']},
            {text: total['q_visited']},
            {text: total['q_actual']},
            {text: total['q_absent']},
            {text: total['q_wff']},
            {text: total['q_forced']},
            {text: '', colSpan: 3},
            {},
            {},
          ]
        );

        parent.push(
          { text: '\n' }, //newline
          {
            columns: [
              {width: '*', text: ''},
              {
                table: {
                  headerRows: 2,
                  widths:[
                    'auto',
                    'auto',
                    'auto',
                    45, //date of reading
                    'auto',
                    'auto',
                    'auto',
                    'auto',
                    'auto',
                    'auto',
                    'auto',
                    'auto',
                    'auto',
                    'auto',
                    'auto',
                    'auto',
                    'auto',
                    'auto',
                    'auto',
                    'auto',
                    'auto',
                    'auto',
                  ],
                  body: table,
                },
                width: 'auto'
              },
              {width: '*', text: ''}
            ]
          }
        );

        let docDef = {
          pageOrientation: 'landscape',
          pageSize: 'LEGAL',
          pageMargins: [15, 96, 15, 96],
          defaultStyle: {
            fontSize: 10,
            font: 'HelveticaNeue'
          },
          header: (currentPage) => {
            return [
              {
                columns: [
                  {
                    text: 'Meralco - Customer Management System',
                    alignment: 'center',
                    bold: true
                  }
                ],
                marginTop: 15
              },
              {
                columns: [
                  {
                    text: 'Handling of Itineraries',
                    alignment: 'center',
                    bold: true
                  }
                ]
              },
              {
                columns: [
                  {
                    text: 'Unloaded from the HHT\'s',
                    alignment: 'center',
                    bold: true
                  }
                ]
              },
              {text: '\n'}, //new line
              {
                columns: [
                  {
                    text: [{text: 'User:', bold: true}, ` ${this.getUser()['email']}`],
                    width: 300
                  },
                  {
                    text: [{text: 'Reading Center:', bold: true}, ` ${readingCenter}`],
                    width: 300
                  },
                  {
                    text: [{text: 'Date:', bold: true}, ` ${this.datePipe.transform(date, 'M/d/yy')}`]
                  },
                  {
                    text: [{text: 'Time:', bold: true}, ` ${time}`]
                  },
                  {
                    text: [{text: 'Page:', bold: true}, ` ${currentPage}`]
                  },
                ],
                marginLeft: 15
              },
              {canvas: [{ type: 'line', x1: 15, y1: 5, x2: 990, y2: 5, lineWidth: 2 }]}
            ]
          },
          content: parent
        }

        pdfMake.createPdf(docDef).open();
        this.isGenerating = false;
      })
    })
  }

  printAdditionOfItineraries(params){
    this.isGenerating = true;
    let readingCenter = params.readingCenterCode;
    this.hhtApiService.getReadingCenters(params.readingCenterCode).subscribe(readingCenterData =>{
      const readingCenterResults = readingCenterData['body']['results'];
      if (readingCenterResults.length === 1) {
        readingCenter = readingCenterResults[0].description;
      }

      this.hhtApiService.printAdditionOfItinerariesReports(params).subscribe(data =>{
        console.log(data['body']['results'])
        let results = data['body']['results'];
        const date = data['body']['date'];
        const time = data['body']['time'];
        let parent: Array<any> = [];
        const table: Array<any> = [];
        table.push(
          [
            {text: 'R/C', alignment: 'center', bold: true},
            {text: 'Route', alignment: 'center', bold: true},
            {text: 'Cycle', alignment: 'center', bold: true},
            {text: 'Itin. No.', alignment: 'center', bold: true},
            {text: 'Theor. Rdg. Date', alignment: 'center', bold: true},
            {text: 'Action', alignment: 'center', bold: true},
          ]
        );
        results.forEach(result=>{
          table.push(
            [
              {text: result['readingCenterCode']},
              {text: result['route']},
              {text: result['cycle']},
              {text: result['itineraryNo']},
              {text: this.datePipe.transform(result['theoreticalReadDate'], 'M/d/yy') || ''},
              {text: result['action']},
            ]
          );
        });
  
        parent.push(
          { text: '\n' }, //newline
          {
            columns: [
              {width: '*', text: ''},
              {
                table: {
                  headerRows: 1,
                  widths:['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                  body: table
                },
                width: 'auto'
              },
              {width: '*', text: ''}
            ]
          }
        );
  
        let docDef = {
          pageOrientation: 'landscape',
          pageSize: 'LEGAL',
          pageMargins: [96, 96, 96, 96],
          defaultStyle: {
            fontSize: 12,
            font: 'HelveticaNeue'
          },
          header: (currentPage) => {
            return [
              {
                columns: [
                  {
                    text: 'Meralco - Customer Management System',
                    alignment: 'center',
                    bold: true
                  }
                ],
                marginTop: 30
              },
              {
                columns: [
                  {
                    text: 'ADDITION OF ITINERARIES RECEIVED IN THE READING CENTER',
                    alignment: 'center',
                    bold: true
                  }
                ]
              },
              {text: '\n'}, //new line
              {
                columns: [
                  {
                    text: [{text: 'Reading Center:', bold: true}, ` ${readingCenter}`]
                  },
                  {
                    text: [{text: 'Date:', bold: true}, ` ${this.datePipe.transform(date, 'M/d/yy')}`]
                  },
                  {
                    text: [{text: 'Time:', bold: true}, ` ${time}`]
                  },
                  {
                    text: [{text: 'Page:', bold: true}, ` ${currentPage}`]
                  },
                ],
                marginLeft: 96
              },
              {canvas: [{ type: 'line', x1: 96, y1: 5, x2: 890, y2: 5, lineWidth: 2 }]}
            ]
          },
          content: parent
        }
  
        pdfMake.createPdf(docDef).open();
        this.isGenerating = false;
      })
    });
  }

  printPreviewOfItinerary(itineraryData: any){
      let tableBody: Array<any> = [];
      tableBody.push([
        {text: 'Lot Address', alignment: 'center', bold: true},
        {text: 'Readings', alignment: 'center', bold: true},
        {text: 'Access Description', alignment: 'center', bold: true}
      ])
      itineraryData['lots'].forEach(itn => {
        tableBody.push([
          {text: `${itn['street'] || ''}, ${itn['houseNo'] || ''}, ${itn['barangay'] || ''}, ${itn['municipality'] || ''}, ${itn['province'] || ''}`},
          {text: !!itn['meteringPoints'] ? `${itn['meteringPoints'][0]['meters'][0]['readings']}` : '-'},
          {text: `${itn['accessDescription'] || '-'}`}
        ])
      });

      let parent: Array<any> = [
        {
          columns: [
            {width: '*', text: ''},
            {
              table: {
                headerRows: 1,
                widths:['auto', 'auto', 'auto'],
                body: tableBody
              },
              width: 'auto'
            },
            {width: '*', text: ''}
          ]
        }
      ];

      let docDef = {
        pageOrientation: 'landscape',
        pageSize: 'LEGAL',
        pageMargins: [96, 120, 96, 96],
        defaultStyle: {
          fontSize: 12,
          font: 'HelveticaNeue'
        },
        header: (currentPage) => {
          return [
            {
              columns: [
                {
                  text: `Itinerary ${itineraryData['itineraryCode']}`,
                  alignment: 'center',
                  bold: true,
                  fontSize: 20
                }
              ],
              marginTop: 30
            },
            {text: '\n'}, //new line
            {
              columns: [
                {
                  text: [{text: 'Route:', bold: true}, ` ${itineraryData['route']}`],
                  width: 100
                },
                {
                  text: [{text: 'Cycle:', bold: true}, ` ${itineraryData['cycle']}`],
                  width: 100
                },
                {
                  text: [{text: 'Status:', bold: true}, ` ${itineraryData['status']}`]
                },
                {
                  text: [{text: 'Assign to:', bold: true}, ` ${itineraryData['device_code']}`]
                },
                {
                  text: [{text: 'Reader:', bold: true}, ` ${itineraryData['readerCode']}`]
                },
              ],
              marginLeft: 96
            },
            {canvas: [{ type: 'line', x1: 96, y1: 5, x2: 890, y2: 5, lineWidth: 2 }]}
          ]
        },
        content: parent
      }

      pdfMake.createPdf(docDef).open();
  }

  getIsGenerating(): boolean {
    return this.isGenerating;
  }
}
