import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-timed',
  templateUrl: './timed.component.html',
  styleUrls: ['./timed.component.scss']
})
export class TimedComponent implements OnInit {
  @Input() information: string;
  @Input() modalTime: number;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {

    setTimeout(() => {
      this.activeModal.close('Close click')
    }, this.modalTime * 1000);

  }

}
