import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms'; 
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { take } from 'rxjs/operators';
import { InformationComponent } from '@shared/modals/information/information.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-online-meter-reading',
  templateUrl: './online-meter-reading.component.html',
  styleUrls: ['./online-meter-reading.component.scss']
})
export class OnlineMeterReadingComponent implements OnInit {
  @ViewChild('alo030', {static: false})
  alo030UploadElement: ElementRef;

  readingCenterCodeSearchString: string = '';
  itinerarySearchString: string = '';
  limit: number = 5;
  offset: number = 0;
  selectedDeviceCode: any;
  selectedDeviceCodeIndex: number = null;
  selectedLots: any;
  selectedLotsIndex: number = null;
  selectedMp: any;
  selectedMpIndex: number = null;
  selectedMeter: any;
  selectedMeterIndex: number = null;

  fileName: string = '';
  alo030File: File = null;
  aloUploadIsLoading: boolean = false;

  deviceCodeArray: Array<any> = [];
  deviceCodeDisplayArray: Array<any> = [];
  deviceCodeIsLoading: boolean = false;
  deviceCodeCollectionSize: number = 0;
  deviceCodePage: number = 1;
  alo040DownloadIsLoading: boolean = false;
  alo045DownloadIsLoading: boolean = false;
  msd010DownloadIsLoading: boolean = false;

  itineraryArray: Array<any> = [];
  itineraryDisplayArray: Array<any> = [];
  itineraryIsLoading: boolean = false;
  itineraryCollectionSize: number = 0;
  itineraryPage: number = 1;

  lotsArray: Array<any> = [];
  lotsDisplayArray: Array<any> = [];
  lotsIsLoading: boolean = false;
  lotsCollectionSize: number = 0;
  lotsPage: number = 1;

  mpArray: Array<any> = [];
  mpDisplayArray: Array<any> = [];
  mpIsLoading: boolean = false;
  mpCollectionSize: number = 0;
  mpPage: number = 1;

  meterArray: Array<any> = [];
  meterDisplayArray: Array<any> = [];
  meterIsLoading: boolean = false;
  meterCollectionSize: number = 0;
  meterPage: number = 1;

  activeNav: string;
  
  hideLotsTab: boolean = true;
  hideMPTab: boolean = true;
  hideMeterTab: boolean = true;
  hideSeals: boolean = true;

  currentItinerary: any;

  constructor(
    private hhtApiService: HhtApiService,
    private modal: NgbModal
  ) {}

  ngOnInit(){
    this.activeNav = 'ITINERARIES'
    this.getItineraries();
  }

  onFileSelected(event) {
    this.alo030File = event.target.files[0];
    this.fileName = this.alo030File.name;
  }

  uploadAlo030File(){
    this.aloUploadIsLoading = true;
    let reader = new FileReader();
    let deviceCode = String(this.alo030File.name).slice(6, 8); //ALO030xx.TRM, get the last 2 digits using String().slice function
    reader.readAsText(this.alo030File);
    reader.onload = () => {
      let content = String(reader.result).replace(/\n/g, '').replace(/\r/g, '|'); //remove new line and carriage return escape characters and replace it with "|"
      let requestPayload = {
        device_code: deviceCode,
        content: content
      }
      this.hhtApiService.uploadAlo030(requestPayload)
                        .pipe(take(1))
                        .subscribe(result =>{
                          console.log('Success: ', result);
                          let apiResponseMessage = result['body']['results']; //backend will set the modal message
                          //If upload is successful, reset input type file
                          this.aloUploadIsLoading = false;
                          this.alo030UploadElement.nativeElement.value = null;
                          this.alo030File = null;
                          const modalRef = this.modal.open(InformationComponent);
                          modalRef.componentInstance.header = 'Upload Status for File: "'+this.fileName+'"';
                          modalRef.componentInstance.information = apiResponseMessage;
                          modalRef.componentInstance.positiveButton = 'Close';
                          this.getItineraries(); //refresh itineraries table
                        },
                        error =>{
                          //add popup message here maybe?
                          console.error('Error: ', error);
                          let apiResponseMessage = error; //this should be changed because the 'error' parameter may contain technical terms.
                          const modalRef = this.modal.open(InformationComponent);
                          modalRef.componentInstance.header = 'Upload Status for File: "'+this.fileName+'"';
                          modalRef.componentInstance.information = apiResponseMessage;
                          modalRef.componentInstance.positiveButton = 'Close';
                          this.aloUploadIsLoading = false;
                        })
    }
  }

  getItineraries(deviceCode?) { //optional parameter to determine to fetch itinerary table using selected device code
    this.hideLotsTab = true;
    this.hideMPTab = true;
    this.hideMeterTab = true;
    if(!!deviceCode){
      this.itineraryArray = [];
      this.itineraryIsLoading = true;
    }else{
      this.selectedDeviceCodeIndex = null;
      this.selectedDeviceCode = null;
      this.deviceCodeArray = [];
      this.deviceCodeIsLoading = true;
    }
    let params = {
      searchField: this.readingCenterCodeSearchString,
      limit: this.limit,
      offset: this.offset,
      deviceCode: !!deviceCode ? deviceCode : '',
      itineraryNo: !!deviceCode ? this.itinerarySearchString : '',
      readingCenterCode: !!deviceCode ? this.selectedDeviceCode.readingCenterCode : ''
    }
    this.hhtApiService.getItinerariesCIS(params)
                      .pipe(take(1))
                      .subscribe((res: any[])=>{   
                        console.log(res['body']) 
                        if(!!deviceCode) {
                          this.itineraryArray = res['body']['itineraries'];
                          this.itineraryCollectionSize = this.itineraryArray.length;
                          this.pageChange('IT');
                          this.itineraryIsLoading = false;
                        }else{
                          this.deviceCodeArray = res['body']['itineraries'];
                          this.deviceCodeCollectionSize = this.deviceCodeArray.length;
                          this.pageChange('RCC');
                          this.deviceCodeIsLoading = false;
                        }
                      },
                      error =>{
                        console.error('Error fetching data', error);
                        this.deviceCodeIsLoading = false;
                        this.itineraryIsLoading = false;
                      })
  }

  pageChange(type){
    switch(type){
      case 'RCC': //table 1 pagination (Device Code, Reading Center Code)
        this.deviceCodeDisplayArray = this.deviceCodeArray
                .map((elements, index) => ({
                  test: index + 1, ...elements
                }))
                .slice((this.deviceCodePage - 1) * this.limit, (this.deviceCodePage -1) * this.limit + this.limit);
      break;
      case 'IT': //table 2 pagination (Itineraries, Readings)
        this.itineraryDisplayArray = this.itineraryArray
          .map((elements, index) => ({
            test: index + 1, ...elements
          }))
          .slice((this.itineraryPage - 1) * this.limit, (this.itineraryPage -1) * this.limit + this.limit);
      break;
      case 'LOTS': 
        this.lotsDisplayArray = this.lotsArray
          .map((elements, index) => ({
            test: index + 1, ...elements
          }))
          .slice((this.lotsPage - 1) * this.limit, (this.lotsPage -1) * this.limit + this.limit);
      break;
      case 'MP': 
        this.mpDisplayArray = this.mpArray
          .map((elements, index) => ({
            test: index + 1, ...elements
          }))
          .slice((this.mpPage - 1) * this.limit, (this.mpPage -1) * this.limit + this.limit);
      break;
      case 'METER': 
        this.meterDisplayArray = this.meterArray
          .map((elements, index) => ({
            test: index + 1, ...elements
          }))
          .slice((this.meterPage - 1) * this.limit, (this.mpPage -1) * this.limit + this.limit);
      break;
    }
  }

  selectedDeviceCodeRow(data, index){
    if(this.selectedDeviceCodeIndex == index){
      this.selectedDeviceCodeIndex = null;
      this.selectedDeviceCode = null;
    }else{
      this.selectedDeviceCodeIndex = index;
      this.selectedDeviceCode = data;
      this.getItineraries(data.device_code);
    }
  }

  selectedLotsCodeRow(data, index){
    if(this.selectedLotsIndex == index){
      this.selectedLotsIndex = null;
      this.selectedLots = null;
    }else{
      this.selectedLotsIndex = index;
      this.selectedLots = data;
      this.navigateToMp(this.selectedLots);
    }
  }

  selectedMpCodeRow(data, index){
    if(this.selectedMpIndex == index){
      this.selectedMpIndex = null;
      this.selectedMp = null;
    }else{
      this.selectedMpIndex = index;
      this.selectedMp = data;
      this.navigateToMeter(this.selectedMp);
    }
  }

  selectedMeterCodeRow(data, index){
    if(this.selectedMeterIndex == index){
      this.selectedMeterIndex = null;
      this.selectedMeter = null;
    }else{
      this.selectedMeterIndex = index;
      this.selectedMeter = data;
      this.navigateToMp(this.selectedMeter);
    }
  }

  downloadAlo040(rowData){
    this.alo040DownloadIsLoading = true;
    this.hhtApiService.downloadAlo040(rowData['device_code'], rowData['readingCenterCode'])
                      .pipe(take(1))
                      .subscribe(result =>{
                        let alo040String: string = result['body']['content'];
                        alo040String = alo040String.replace(/#/g, '#\r\n'); //add carriage return new line after every #
                        const rcpBlob = new Blob([alo040String], {type: '.RCP'});
                        let a = document.createElement('a');
                        let url = URL.createObjectURL(rcpBlob);
                        a.href = url;
                        a.download = 'ALO040'+rowData['device_code']+'.RCP';
                        a.click();
                        this.alo040DownloadIsLoading = false;
                      },
                      error =>{
                        console.error('Error downloading file', error);
                        this.alo040DownloadIsLoading = false;
                      })
  }

  downloadAlo045(rowData){
    this.alo045DownloadIsLoading = true;
    this.hhtApiService.downloadAlo045(rowData['device_code'], rowData['readingCenterCode'])
                      .pipe(take(1))
                      .subscribe(result =>{
                        let alo045String: string = result['body']['content'];
                        alo045String = alo045String.replace(/#/g, '#\r\n'); //add carriage return new line after every #
                        const rcpBlob = new Blob([alo045String], {type: '.RCP'});
                        let a = document.createElement('a');
                        let url = URL.createObjectURL(rcpBlob);
                        a.href = url;
                        a.download = 'ALO045'+rowData['device_code']+'.RCP';
                        a.click();
                        this.alo045DownloadIsLoading = false;
                      },
                      error =>{
                        console.error('Error downloading file', error);
                        this.alo045DownloadIsLoading = false;
                      })
  }

  downloadMsd010(rowData){
    this.msd010DownloadIsLoading = true;
    this.hhtApiService.downloadMsd010(rowData['device_code'], rowData['readingCenterCode'])
                      .pipe(take(1))
                      .subscribe(result =>{
                        let msd010String: string = result['body']['content'];
                        msd010String = msd010String.replace(/#/g, '#\r\n'); //add carriage return new line after every #
                        const rcpBlob = new Blob([msd010String], {type: '.RCP'});
                        let a = document.createElement('a');
                        let url = URL.createObjectURL(rcpBlob);
                        a.href = url;
                        a.download = 'MSD010'+rowData['device_code']+'.RCP';
                        a.click();
                        this.msd010DownloadIsLoading = false;
                      },
                      error =>{
                        console.error('Error downloading file', error);
                        this.msd010DownloadIsLoading = false;
                      })
  }


  navigateToLots(data?){
    this.lotsIsLoading = true;
    this.hideLotsTab = false;
    this.hideMPTab = true;
    this.hideMeterTab = true;
    this.hideSeals = true;
    this.activeNav = 'LOTS';
    if(!!data){
      this.currentItinerary = data;
    }else{
      data = this.currentItinerary;
    }
    let params = {
      searchField: this.readingCenterCodeSearchString,
      limit: this.limit,
      offset: this.offset,
      deviceCode: this.selectedDeviceCode.device_code,
      itineraryNo: data.itineraryNo,
      readingCenterCode: this.selectedDeviceCode.readingCenterCode
    }
    this.hhtApiService.getLots(params)
                      .pipe(take(1))
                      .subscribe(result =>{
                        this.lotsArray = result['body']['lots'];
                        this.lotsCollectionSize = this.lotsArray.length;
                        this.pageChange('LOTS');
                        this.lotsIsLoading = false;              
                      },
                      error =>{
                        console.error('error fetching lots', error);
                        this.lotsIsLoading = false;
                      })
  }

  navigateToMp(data?){
    this.mpIsLoading = true;
    this.hideMPTab = false;
    this.hideMeterTab = true;
    this.hideSeals = true;
    this.activeNav = 'METERING POINTS';
    if(!!data){
      this.selectedLots = data;
    }else{
      data = this.selectedLots;
    }
    let params = {
      searchField: this.readingCenterCodeSearchString,
      limit: this.limit,
      offset: this.offset,
      deviceCode: this.selectedDeviceCode.device_code,
      lotId: data.lotId,
      readingCenterCode: this.selectedDeviceCode.readingCenterCode
    }
    this.hhtApiService.getMeteringPoints(params)
                      .pipe(take(1))
                      .subscribe(result =>{
                        console.log(result['body']);
                        this.mpArray = result['body']['meteringPoints'];
                        this.mpCollectionSize = this.mpArray.length;
                        this.pageChange('MP');
                        this.mpIsLoading = false;              
                      },
                      error =>{
                        console.error('error fetching lots', error);
                        this.mpIsLoading = false;
                      })
  }

  navigateToMeter(data?){
    this.meterIsLoading = true;
    this.hideMeterTab = false;
    this.hideSeals = true;
    this.activeNav = 'METERS';
    if(!!data){
      this.selectedMp = data;
    }else{
      data = this.selectedMp;
    }
    let params = {
      searchField: this.readingCenterCodeSearchString,
      limit: this.limit,
      offset: this.offset,
      deviceCode: this.selectedDeviceCode.device_code,
      meteringPointId: data.meteringPointId,
      readingCenterCode: this.selectedDeviceCode.readingCenterCode
    }
    this.hhtApiService.getMeters(params)
                      .pipe(take(1))
                      .subscribe(result =>{
                        console.log(result['body']);
                        this.meterArray = result['body']['meters'];
                        this.meterCollectionSize = this.meterArray.length;
                        this.pageChange('METER');
                        this.meterIsLoading = false;              
                      },
                      error =>{
                        console.error('error fetching lots', error);
                        this.meterIsLoading = false;
                      })
  }
}
