// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-container {
  height: 90vh;
}

#map {
  width: 100%;
  height: 100%;
  z-index: 0;
}

.legend-color {
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 8px;
}

.searchBy {
  width: 380px;
}

.btn-circle {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  vertical-align: top;
  font-size: 20px;
}

.searchItinerary {
  margin-right: 10px;
}

.add-icon {
  transform: rotate(45deg);
}

.legend-header {
  color: #2C906B;
  display: flex;
  align-items: center;
  height: 60px;
}

.loader-container {
  z-index: 1;
}

.mmdTbl {
  height: auto;
  min-height: 0;
}

.content-container {
  height: 90vh;
}

.col-left {
  max-height: 100%;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/core/hht/inquiry-map-portal/inquiry-map-portal.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AACA;EACI,WAAA;EACA,YAAA;EACA,UAAA;AAEJ;;AAAA;EACI,WAAA;EACA,YAAA;EACA,qBAAA;EACA,iBAAA;AAGJ;;AADA;EACI,YAAA;AAIJ;;AAFA;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;AAKJ;;AAHA;EACI,kBAAA;AAMJ;;AAJA;EACI,wBAAA;AAOJ;;AALA;EACI,cAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AAQJ;;AANA;EACI,UAAA;AASJ;;AAPA;EACI,YAAA;EACA,aAAA;AAUJ;;AARA;EACI,YAAA;AAWJ;;AATA;EACI,gBAAA;EACA,cAAA;AAYJ","sourcesContent":[".map-container {\r\n    height: 90vh;\r\n}\r\n#map {\r\n    width: 100%;\r\n    height: 100%;\r\n    z-index: 0;\r\n}\r\n.legend-color {\r\n    width: 15px;\r\n    height: 15px;\r\n    display: inline-block;\r\n    margin-right: 8px;\r\n}\r\n.searchBy {\r\n    width: 380px;\r\n}\r\n.btn-circle {\r\n    width: 60px;\r\n    height: 60px;\r\n    border-radius: 10px;\r\n    vertical-align: top;\r\n    font-size: 20px;\r\n}\r\n.searchItinerary {\r\n    margin-right: 10px;\r\n}\r\n.add-icon {\r\n    transform: rotate(45deg);\r\n}\r\n.legend-header {\r\n    color:#2C906B;\r\n    display: flex;\r\n    align-items: center;\r\n    height: 60px;\r\n}\r\n.loader-container {\r\n    z-index: 1;\r\n}\r\n.mmdTbl {\r\n    height: auto;\r\n    min-height: 0;\r\n}\r\n.content-container {\r\n    height: 90vh;\r\n}\r\n.col-left {\r\n    max-height: 100%;\r\n    overflow: auto;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
