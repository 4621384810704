import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import {Map, View, Feature, Overlay} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectoreSource from 'ol/source/Vector';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Point from 'ol/geom/Point';
import Translate from 'ol/interaction/Translate';
import Collection from 'ol/Collection';
import * as olProj from 'ol/proj';
import * as olControl from 'ol/control';
import { BehaviorSubject } from 'rxjs';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { NgxSpinnerService } from "ngx-spinner";
import { StoreService } from '@app/shared/services/store.service';
import { ConfirmComponent } from '../confirm/confirm.component';

export interface Meter{
  lat: string;
  lng: string;
  actualLat: string;
  actualLng: string;
  meterNumber: string;
  consumptionType: string;
  userName: string;
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  @Input() meterDetails: Meter;
  @Input() isUpdate:boolean

  markerIcon = '../../../assets/img/blue-marker-modal.svg';
  markerDestination = '../../../assets/img/destination-marker.svg';
  markerOrigin = '../../../assets/img/origin-marker.svg';
  marker: any;
  address: string;
  infoContent: string;
  displayLocation: boolean;
  displayError: boolean;
  displayMap: boolean;
  displayConfirmation: boolean;
  modalRef: NgbModalRef;
  modalChoice;
  map;
  meterXY;
  actualMeterXY;
  originDestinationXY;
  newCoordinates = [];
  markerLayer;
  newMarker;

  constructor(public activeModal: NgbActiveModal,
              private modal: NgbModal,
              public hhtApiService: HhtApiService,
              private spinnerService: NgxSpinnerService,
              private storeService: StoreService){}


  ngOnInit() {
    let center = [0,0];
    this.displayMap = true;
    this.displayConfirmation = false;
    this.modalChoice = new BehaviorSubject<string>('');
    this.meterXY = olProj.fromLonLat([Number(this.meterDetails.lng),Number(this.meterDetails.lat)]);
    this.actualMeterXY = olProj.fromLonLat([Number(this.meterDetails.actualLng),Number(this.meterDetails.actualLat)]);
    this.storeService.set('isLocationUpdated', false);

    if(this.isUpdate){
      const lng = this.meterDetails.actualLng==null || this.meterDetails.actualLng.trim().length===0 ? "A": this.meterDetails.actualLng.trim();
      const lat = this.meterDetails.actualLat==null || this.meterDetails.actualLat.trim().length===0 ? "B": this.meterDetails.actualLat.trim();
      center = olProj.fromLonLat([Number(lng), Number(lat)]);
      this.newCoordinates = center;
    }else{
      center = this.meterXY;
    }
    
    if(isNaN(center[0]) || isNaN(center[1])){
      this.displayMap = false;  
    }else{
      this.loadMap(center);
    }
    
  }

  loadMap(center){
    this.map = new Map({
      layers: [
        new TileLayer({
          source: new OSM(),
        }) ],
      target: 'map',
      view: new View({
        center: center,
        zoom: 17,
      }),
      controls: olControl.defaults({attribution: false, zoom: false})
    });

    if(this.isUpdate){
      let destinationMarkerLayer = new VectorLayer({
        source: new VectoreSource(),
        style: new Style({
          image: new Icon({
            //anchor: [0.5, 0.5], //[0.5, 1],
            src: this.markerDestination
          })
        }),
        className: 'destination-marker',
        updateWhileAnimating: true,
        updateWhileInteracting: true
      });
  
      let originMarkerLayer = new VectorLayer({
        source: new VectoreSource(),
        style: new Style({
          image: new Icon({
            anchor: [0.5, 1],
            src: this.markerOrigin
          })
        }),
        className: 'origin-marker',
        updateWhileAnimating: true,
        updateWhileInteracting: true
      });
  
      this.map.addLayer(destinationMarkerLayer);
      this.map.addLayer(originMarkerLayer);
      
      let destinationMarker = new Feature({
        geometry: new Point(center),
        name: 'Destination Marker'
      });
      let originMarker = new Feature({
        geometry: new Point(this.meterXY),
        name: 'Origin Marker'
      });

      destinationMarkerLayer.getSource().addFeature(destinationMarker);
      originMarkerLayer.getSource().addFeature(originMarker);

    }

    this.markerLayer = new VectorLayer({
      source: new VectoreSource(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: this.markerIcon
        })
      }),
      className: 'marker',
      updateWhileAnimating: true,
      updateWhileInteracting: true
    });

    this.map.addLayer(this.markerLayer);

    let marker = new Feature({
      geometry: new Point(center),
      name: 'Marker'
    });

    this.markerLayer.getSource().addFeature(marker);
    
    this.map.on('pointermove', (event) => {
      this.displayLocation = false;
      this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
            if(feature.get('name')==='Marker'){
              this.displayLocation = true;             
              const returnCoord = feature.get('geometry').flatCoordinates;
              const convertedCoord = olProj.toLonLat(returnCoord);
              if(this.isUpdate && returnCoord.length!==0 && returnCoord[0]!==this.actualMeterXY[0] && returnCoord[1]!==this.actualMeterXY[1]){
                this.infoContent = "Lng: "+convertedCoord[0]+", Lat: "+convertedCoord[1];
              }else if(this.isUpdate){
                this.infoContent = "Lng: "+this.meterDetails.actualLng+", Lat: "+this.meterDetails.actualLat;
              }else{
                this.infoContent = "Lng: "+this.meterDetails.lng+", Lat: "+this.meterDetails.lat;
              }             
            }else if(feature.get('name')==='Origin Marker' && this.isUpdate){
              this.displayLocation = true;
              this.infoContent = "Lng: "+this.meterDetails.lng+", Lat: "+this.meterDetails.lat;
            }else if(feature.get('name')==='Destination Marker' && this.isUpdate){
              this.displayLocation = true;
              this.infoContent = "Lng: "+this.meterDetails.actualLng+", Lat: "+this.meterDetails.actualLat;
            }
        })
    });

    if(this.isUpdate){
      const translate = new Translate({
        features: new Collection([marker])
      });
  
      this.map.addInteraction(translate);
  
      translate.on('translateend', (event) => {
        this.map.getView().setCenter(event.coordinate);
        this.newCoordinates = event.coordinate;
      });

      this.map.on('click', (event) => {
        this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
          if(feature.get('name')==='Marker'){
              const coordXY = feature.get('geometry').flatCoordinates;
              this.updateLocation(coordXY);
          }else if((feature.get('name')==='Origin Marker' || feature.get('name')==='Destination Marker') && this.isUpdate){
              this.originDestinationXY = feature.get('geometry').flatCoordinates;
              this.focusToOriginDestinationMarker();
              this.map.getView().setCenter(feature.get('geometry').flatCoordinates);
              this.newCoordinates = feature.get('geometry').flatCoordinates;
          }
        })
      });

    }
    
    this.map.on('moveend', ()=>{
      if(this.isUpdate){
        this.map.getView().setCenter(this.newCoordinates);
      }else{
        this.map.getView().setCenter(center);
      }
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  removeMarker(){
    this.map.getLayers().forEach((layer, index) => {
      if(layer!== undefined && layer.className_ === 'marker'){
        this.map.getLayers().removeAt(index);
      }    
    });
  }

  focusToOriginDestinationMarker(){
    this.removeMarker();
    this.map.addLayer(this.markerLayer);
    this.markerLayer.getSource().clear();
    this.newMarker = new Feature({
      geometry: new Point(this.originDestinationXY),
      name: 'Marker'
    });
    this.markerLayer.getSource().addFeature(this.newMarker);

    const newTranslate = new Translate({
      features: new Collection([this.newMarker])
    });          
    this.map.addInteraction(newTranslate);

    newTranslate.on('translateend', (event) => {
      this.map.getView().setCenter(event.coordinate);
      this.newCoordinates = event.coordinate;
    });
  }

  openConfirmDialog(information) {
    this.modalRef = this.modal.open(ConfirmComponent, { centered : true});
    this.modalRef.componentInstance.information = information;
    this.modalRef.componentInstance.positiveButton = 'Proceed';
    this.modalRef.componentInstance.negativeButton = 'Cancel';
    this.modalRef.result.then((result) => {
      this.modalChoice.next(result);
    }, (reason) => {
      this.modalChoice.next(reason)
    });
  }

  updateLocation(coordXY){
    if(coordXY[0]!==this.meterXY[0] && coordXY[1]!==this.meterXY[1]){
      this.openConfirmDialog('Are you sure you want to update location?');
      this.modalChoice.asObservable().subscribe(choice =>{
        if(choice === 'Proceed'){
          let newLng = "";
          let newLat = "";
          if(coordXY[0]===this.actualMeterXY[0] && coordXY[1]===this.actualMeterXY[1]){
            newLng = this.meterDetails.actualLng;
            newLat = this.meterDetails.actualLat;
          }else{
            const returnCoord = olProj.toLonLat(coordXY);
            newLng = returnCoord[0].toString();
            newLat = returnCoord[1].toString();
          }
          
          const body = {
            new_longitude: newLng,
            new_latitude: newLat,
            meterNumber: this.meterDetails.meterNumber,
            consumptionType: this.meterDetails.consumptionType,
            old_longitude: this.meterDetails.lng,
            old_latitude: this.meterDetails.lat,
            userName: this.meterDetails.userName
          }

          console.log('update request', body);

          this.spinnerService.show();
          this.hhtApiService.updateMeterLocation(body).subscribe(res => {
              console.log('meterNumber',this.meterDetails.meterNumber)
              if(res['body'].Master_Meter[0].RESULT === 1){
                this.storeService.set('isLocationUpdated', true);
                this.storeService.set('updatedMeterNumber', this.meterDetails.meterNumber);               
              }
              this.spinnerService.hide();
              this.map.disposeInternal();
              this.closeModal(); 
          })
        }
      });       
    }else{
      this.displayError = true;
      setTimeout(() => {
        this.displayError = false;
      }, 2500);
    } 
  }


}
