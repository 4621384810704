import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HhtMainComponent } from './hht-main/hht-main.component';
import { RouterModule } from '@angular/router';
import { hhtRoutes } from './hht-routing.module';
import { ItineraryComponent } from './itinerary/itinerary.component';
import { MasterMeterDataComponent } from './master-meter-data/master-meter-data.component';
import { MeterLocationHistoryComponent } from './meter-location-history/meter-location-history.component';
// import { BrowserModule } from '@angular/platform-browser';
import { FormsModule ,ReactiveFormsModule} from '@angular/forms'
import { GoogleMapsModule ,GoogleMap } from '@angular/google-maps';
import { ItineraryListComponent } from './itinerary-list/itinerary-list.component';
// import { AgmCoreModule } from '@agm/core';
// AgmCoreModule.forRoot({
//   apiKey: 'AIzaSyBVQTVxw-YgCItkjtgnSTJjFRLs90KyswE'
// })
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MeterComponent } from './meter/meter.component';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { ItineraryMapComponent } from './itinerary-map/itinerary-map.component';

import { SharedModule } from '@app/shared/shared.module';
import { ItineraryMapDetailsComponent } from './itinerary-map-details/itinerary-map-details.component';
import { ItineraryDetailsComponent } from './itinerary-details/itinerary-details.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { SearchFilterPipe } from '../../shared/pipes/search-filter.pipe'
import { TableFilterPipe } from '../../shared/pipes/table-filter.pipe';
import { StoreService } from '@app/shared/services/store.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { OnlineMeterReadingComponent } from './online-meter-reading/online-meter-reading.component';
import { MaintenanceComponent } from './online-meter-reading/maintenance/maintenance.component';
import { UserMaintenanceComponent } from './user-maintenance/user-maintenance.component';
import { ImageCaptureHistoryComponent } from './image-capture-history/image-capture-history.component';
import { InquiryMapPortalComponent } from './inquiry-map-portal/inquiry-map-portal.component';
import { SplitItineraryComponent } from './split-itinerary/split-itinerary.component';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReportsComponent } from './reports/reports.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { NumberOnlyDirective } from '@app/directives/number-only.directive';
import { MatIconModule } from '@angular/material/icon';
import { UppercaseOnlyDirective } from '@app/directives/uppercase-only.directive';
import { NoSpaceDirective } from '@app/directives/no-space.directive';

@NgModule({
  declarations: [
    HhtMainComponent, 
    ItineraryComponent, 
    MasterMeterDataComponent,
    MeterLocationHistoryComponent,
    ImageCaptureHistoryComponent,
    ItineraryListComponent, 
    MeterComponent, 
    ItineraryMapComponent, ItineraryMapDetailsComponent, ItineraryDetailsComponent,
    SearchFilterPipe,
    TableFilterPipe,
    ChangePasswordComponent,
    OnlineMeterReadingComponent,
    MaintenanceComponent,
    UserMaintenanceComponent,
    InquiryMapPortalComponent,
    SplitItineraryComponent,
    ReportsComponent,
    MyAccountComponent,
    NumberOnlyDirective,
    UppercaseOnlyDirective,
    NoSpaceDirective
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(hhtRoutes),
    GoogleMapsModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AutocompleteLibModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    MatSelectModule,
    MatFormFieldModule,
    SharedModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule
  ],
  providers: [
    GoogleMap,
    HhtApiService,
    StoreService
  ]
})
export class HhtModule { }
