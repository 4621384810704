import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hht-main',
  templateUrl: './hht-main.component.html',
  styleUrls: ['./hht-main.component.scss']
})
export class HhtMainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
