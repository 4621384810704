import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[numbers-only]'
})
export class NumberOnlyDirective {

  constructor(private el: ElementRef, private ngControl: NgControl) { }

  @HostListener('input', ['$event']) 
  onInputChange(event) {
		const initalValue = this.el.nativeElement.value;
		this.el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
		if (initalValue !== this.el.nativeElement.value) {
			event.stopPropagation();
		}
		this.ngControl.control.setValue(this.el.nativeElement.value );
	}

}
