import { Component, OnInit, Input } from '@angular/core';
import { Meter } from '../models/Meter';
import { ModalService } from '@app/shared/services/modal.service';

@Component({
  selector: 'app-meter',
  templateUrl: './meter.component.html',
  styleUrls: ['./meter.component.css']
})
export class MeterComponent implements OnInit {


  @Input() meter:Meter

  constructor(private modalService:ModalService) { }

  ngOnInit(): void {

    // console.log('meter -->',this.meter)

  }

  viewImageOnClick(){
    console.log('viewImageOnClick url --->' , this.meter.imageAttachment)
  }

  viewMapOnClick(){
    console.log('viewMapOnClick lat --->',this.meter.readingLocation.lat())
    console.log('viewMapOnClick lng --->',this.meter.readingLocation.lng())
    this.modalService.openMapModal(this.meter.readingLocation,false)
  }

  updateCoordinates(){
    console.log('updateCoordinates')
    this.modalService.openMapModal(this.meter.readingLocation,true)
  }

}
