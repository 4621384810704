// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location-update-msg {
  top: 10px;
  right: 18px;
  position: absolute;
  width: 280px;
  height: 105px;
  background: var(--unnamed-color-2c906b) 0% 0% no-repeat padding-box;
  background: #2C906B 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1019607843);
  border-radius: 6px;
  opacity: 1;
  cursor: pointer;
}

.msg-title {
  text-align: left;
  font: normal normal bold 16px/19px Helvetica Neue, sans-serif;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  padding-left: 20px;
  padding-top: 20px;
}

.msg-text {
  text-align: left;
  font: normal normal normal 16px/18px Helvetica Neue, sans-serif;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-top: 10px;
}

.paginationDiv .ngb-pagination {
  display: flex;
}

table tbody tr.no-data-found {
  background-color: transparent;
  cursor: default;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/core/hht/master-meter-data/master-meter-data.component.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,mEAAA;EACA,+CAAA;EACA,oDAAA;EACA,kBAAA;EACA,UAAA;EACA,eAAA;AACJ;;AAEA;EAEI,gBAAA;EACA,6DAAA;EACA,mBAAA;EACA,cAAA;EACA,UAAA;EACA,kBAAA;EACA,iBAAA;AAAJ;;AAGA;EAEI,gBAAA;EACA,+DAAA;EACA,mBAAA;EACA,cAAA;EACA,UAAA;EACA,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,iBAAA;AADJ;;AAII;EACI,aAAA;AADR;;AAKA;EACI,6BAAA;EACA,eAAA;EACA,kBAAA;AAFJ","sourcesContent":[".location-update-msg{\r\n    top: 10px;\r\n    right: 18px;\r\n    position: absolute;\r\n    width: 280px;\r\n    height: 105px;\r\n    background: var(--unnamed-color-2c906b) 0% 0% no-repeat padding-box;\r\n    background: #2C906B 0% 0% no-repeat padding-box;\r\n    box-shadow: 0px 5px 10px #0000001A;\r\n    border-radius: 6px;\r\n    opacity: 1;\r\n    cursor: pointer;\r\n}\r\n\r\n.msg-title{\r\n    // color: var(--unnamed-color-ffffff);\r\n    text-align: left;\r\n    font: normal normal bold 16px/19px Helvetica Neue,sans-serif;\r\n    letter-spacing: 0px;\r\n    color: #FFFFFF;\r\n    opacity: 1;\r\n    padding-left: 20px;\r\n    padding-top: 20px;\r\n}\r\n\r\n.msg-text{\r\n    // color: var(--unnamed-color-ffffff);\r\n    text-align: left;\r\n    font: normal normal normal 16px/18px Helvetica Neue,sans-serif;\r\n    letter-spacing: 0px;\r\n    color: #FFFFFF;\r\n    opacity: 1;\r\n    padding-left: 20px;\r\n    padding-bottom: 20px;\r\n    padding-right: 20px;\r\n    padding-top: 10px;\r\n}\r\n.paginationDiv{\r\n    .ngb-pagination{\r\n        display: flex;\r\n    }\r\n}\r\n\r\ntable tbody tr.no-data-found {\r\n    background-color: transparent;\r\n    cursor: default;\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
