import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';
import { ModalService } from '@app/shared/services/modal.service';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-itinerary-details',
  templateUrl: './itinerary-details.component.html',
  styleUrls: ['./itinerary-details.component.scss']
})
export class ItineraryDetailsComponent implements OnInit, OnChanges{
  isLoading: boolean = true;
  isFilterToggled: boolean = false;

  meterRequest: any = [];
  // noImage:boolean;

  @Input() list: any;
  @Input() itineraryNumber: number;
  @Input() readings: any;
  @Input() deviceCode: number;
  @Input() readerCode: number;
  @Input() dataFilter: any;
  @Input() completionPercent: any;
  @Input() month: any;
  @Input() year: any;
  @Input() startTime: string = '';
  @Input() endTime: string = '';
  @Input() email: string = '';
  @Input() hideLocationViewAndUpdate: boolean = false;
  @Output() navigateToLocation: EventEmitter<any> = new EventEmitter();

  page=1;
  pageSize = 10;
  totalCount: number;
  noImage:boolean;
  p: number = 1;
  public searchText : string;

  defaultFilter: string =  "ALL";

  monthParse = {
    "01":"January",
    "02":"February",
    "03":"March",
    "04":"April",
    "05":"May",
    "06":"June",
    "07":"July",
    "08":"August",
    "09":"September",
    "10":"October",
    "11":"November",
    "12":"December"
  }
  
  constructor(
    private modalService:ModalService,
    private hhtApiService: HhtApiService,
    private datePipe: DatePipe
    ) { }

   //detects changes from list input
  ngOnChanges(changes: SimpleChanges){
    for (const propName in changes) {
      const change = changes[propName];
      if(propName == 'list'){ //temporary fix for template parse errors. In the future, please avoid using ngOnChanges please.
        this.assignRequest(change.currentValue);
      }
    }
  }


  ngOnInit() {
    console.log('readings',this.completionPercent);
    this.meterRequest = this.list
    // this.meterRequest = typeof this.list === "object" ? this.list.map(data => ({
    //   ...data,
    //   readings: data.readings!=="null" ? data.readings:"",
    //   meter_field_finding: data.meter_field_finding!=="null" ? data.meter_field_finding:""
    // })): this.list;
  }

  onKeyupEnterSearch() {
    if(typeof this.searchText !== "undefined" && this.searchText.length === 0){
      this.meterRequest = this.list;
      this.totalCount = typeof this.meterRequest === "object" ? this.meterRequest.length: 0;
    }else if(typeof this.searchText !== "undefined" && this.searchText.length > 0){
      this.meterRequest = this.list;
      this.meterRequest = this.meterRequest.filter(data => data.meter_number.toLowerCase().includes(this.searchText.toLowerCase()));
      this.totalCount = this.meterRequest.length;
    }
  }

  assignRequest(list: any){
    if(typeof list === "undefined" || typeof list === "string" || (typeof list === "object" && list.length==0)){
      this.totalCount = 0;
      this.meterRequest = null;
      return
    }
    
    // this.meterRequest = list.map(data => ({
    //   ...data,
    //   readings: data.readings!=="null" ? data.readings:"",
    //   meter_field_finding: data.meter_field_finding!=="null" ? data.meter_field_finding:""
    // }));
    this.meterRequest = list
    
    this.totalCount = typeof this.meterRequest[0] === "object" ? list.length : this.totalCount;
    console.log(this.meterRequest)
    this.isLoading = false;
  }

  displayMap(meter, isUpdate){
    let meterDetails = {
      lat: meter.latitude,
      lng: meter.longitude,
      actualLat: meter.actual_reading_latitude,
      actualLng: meter.actual_reading_longitude,
      meterNumber: meter.meter_number,
      consumptionType: meter.meter_consumption_type,
    }
    this.modalService.openMapModal(meterDetails, isUpdate);
  }

  viewImage(meter){
    console.log(meter)
    this.modalService.openCarousel(meter.meter_number, meter.meter_consumption_type, meter.meter_read_timestamp).result.then(() => {
      console.log('TRUE')
    }).catch(() => {
      console.log('FALSE')

    });
  }

  isNotForUpdate(meter):boolean{
    const lng = meter.longitude!=null && meter.longitude.trim().length!==0 ? meter.longitude.trim(): "A";
    const lat = meter.latitude!=null && meter.latitude.trim().length!==0 ? meter.latitude.trim(): "B";
    console.log('meter.for_location_update',meter.for_location_update)
    return  meter.for_location_update == 0 ||
            meter.for_location_update == null ||
            meter.longitude == null ||
            meter.latitude == null ||
            isNaN(Number(lng)) ||
            isNaN(Number(lat));
  }

  onChangeFilter(){

    // switch(e.target.value){
    //   case "STATUS":{
    //     this.hhtApiService.getItineraryById(this.itineraryNumber, this.readings, this.deviceCode, this.readerCode, e.target.value, this.month, this.year).subscribe(resp=>{
    //       this.meterRequest = resp['body']['listofMeter']
    //     })
    //   }
    //   case "FIELD_FINDINGS":{
    //     this.hhtApiService.getItineraryById(this.itineraryNumber, this.readings, this.deviceCode, this.readerCode, e.target.value, this.month, this.year).subscribe(resp=>{
    //       this.meterRequest = resp['body']['listofMeter']
    //     })
    //   }
    //   default:
    //     this.hhtApiService.getItineraryById(this.itineraryNumber, this.readings, this.deviceCode, this.readerCode, e.target.value, this.month, this.year).subscribe(resp=>{
    //       this.meterRequest = resp['body']['listofMeter']
    //     })
    // }
    this.isLoading = true;
    if(this.defaultFilter === "ALL" || this.defaultFilter === "STATUS" || this.defaultFilter === "FIELD_FINDINGS"){
      let center_code = this.itineraryNumber.toString().slice(0,4); //add center code
      this.hhtApiService.getItineraryById(this.itineraryNumber, this.readings, this.deviceCode, center_code, this.defaultFilter, this.month, this.year,this.email).subscribe(resp=>{
        let filteredRequest = resp['body']['listofMeter']
        this.startTime = resp['body']['read_start_time'];
        this.endTime = resp['body']['read_end_time'];
        this.assignRequest(filteredRequest);
        this.isLoading = false;
      })
    }
  }

  omit_special_char(event)
  {   
    var k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
  }

  navigate(){
    this.navigateToLocation.emit();
  }

}
