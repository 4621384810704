import { AuthService } from '@shared/services/guards/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '@environments/environment';

@Injectable()
export class AuthGuard  {

  route: any;
  constructor(
    private _authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    return this._authService.isAuthenticated()
    .then(result => {
      if(result){
        return true;
      }
      else {
          localStorage.setItem('prev_url', state.url)
          window.location.replace(environment.signIn);
          return false;
        }
      });
  }
}