import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from '@shared/modals/confirm/confirm.component';
import { InformationComponent } from '@shared/modals/information/information.component';
import { TimedComponent  } from '@shared/modals/timed/timed.component';
import { MapComponent } from '../modals/map/map.component';
import { CarouselComponent } from '@shared/modals/carousel/carousel.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
    public modalRef: NgbModalRef;

    constructor(private modalService: NgbModal) {}

    public openConfirmDialog(title, information, positiveButton?: string, negativeButton?: string ) {
        if (positiveButton === void 0) { positiveButton = 'Confirm'; }
        if (negativeButton === void 0) { negativeButton = 'Cancel'; }

        this.modalRef = this.modalService.open(ConfirmComponent, { centered : true});
        this.modalRef.componentInstance.title = title;
        this.modalRef.componentInstance.information = information;
        this.modalRef.componentInstance.positiveButton = positiveButton;
        this.modalRef.componentInstance.negativeButton = negativeButton;
        return this.modalRef

    }

    public openInfoDialog(title, information, positiveButton?: string) {
        if (positiveButton === void 0) { positiveButton = 'OK'; }
        this.modalRef = this.modalService.open(InformationComponent, { centered : true});
        this.modalRef.componentInstance.title = title;
        this.modalRef.componentInstance.information = information;
        this.modalRef.componentInstance.positiveButton = positiveButton;
        return this.modalRef
    }

    public openTimedDialog(title, information, seconds: number) {
        this.modalRef = this.modalService.open(TimedComponent, { centered : true});
        this.modalRef.componentInstance.title = title;
        this.modalRef.componentInstance.information = information;
        this.modalRef.componentInstance.modalTime = seconds;
        return this.modalRef
    }

    public openMapModal(meterDetails,isUpdate:boolean) {
        this.modalRef = this.modalService.open(MapComponent, { centered : true});        
        this.modalRef.componentInstance.meterDetails = meterDetails
        this.modalRef.componentInstance.isUpdate = isUpdate
        return this.modalRef
    }

    public openCarousel(meter_number, consumption_type, actual_reading_date) {
        this.modalRef = this.modalService.open(CarouselComponent, { centered : true});
        this.modalRef.componentInstance.meter_number = meter_number;
        this.modalRef.componentInstance.consumption_type = consumption_type;
        this.modalRef.componentInstance.actual_reading_date = actual_reading_date;
        return this.modalRef
    }
    
}