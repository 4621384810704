import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  hasSpecialCharacters(testString: string): boolean{
    let symbolRegex = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
    return symbolRegex.test(testString);
  }

  hasOnlyWhiteSpaces(control: FormControl){ //custom form validators to check if input only has whitespaces in it
    const isWhiteSpace = (control.value || '').trim().length === 0;
    const isValid = !isWhiteSpace;
    return isValid ? null : {'whitespace': true};
  }
}
