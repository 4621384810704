// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fill-content {
  margin: auto;
  width: 100%;
  height: 100%;
  min-width: 10rem;
}

.mat-select-width {
  width: 100%;
}

.checkbox-max-width {
  max-width: 3em;
}

.modal-title {
  color: #171C8F;
  font-size: 30px;
  font-family: "Trade Gothic Bold";
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/core/hht/split-itinerary/split-itinerary.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,cAAA;EACA,eAAA;EACA,gCAAA;EACA,kBAAA;AACJ","sourcesContent":[".fill-content {\r\n    margin: auto;\r\n    width: 100%;\r\n    height: 100%;\r\n    min-width: 10rem;\r\n}\r\n\r\n.mat-select-width {\r\n    width: 100%\r\n}\r\n\r\n.checkbox-max-width {\r\n    max-width: 3em;\r\n}\r\n\r\n.modal-title{\r\n    color: #171C8F;\r\n    font-size: 30px;\r\n    font-family: 'Trade Gothic Bold';\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
