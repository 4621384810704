import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Auth } from 'aws-amplify';
import { AuthService } from '@shared/services/guards/auth.service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuard  {
  
  constructor(private router: Router,
    private authService: AuthService){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!!this.authService.getCurrentUser().email){
        let currentUser = this.authService.getCurrentUser();
        let isAuthenticated = !['MDOA_STAFF', 'METER_READER', 'OCR_METER_READER'].includes(String(currentUser.accountType)) &&
                              (!!currentUser.isAD || (!currentUser.isAD && new Date(currentUser.expirationDate) >= new Date()));
        if(!isAuthenticated){
          this.router.navigate(['']);
        }
        return isAuthenticated;
      }else{
        return Auth.currentUserInfo().then((user)=>{
          return this.authService.assignCurrentUser(String(user['username']).replace(environment.adPrefix, '')).then(()=>{
            let currentUser = this.authService.getCurrentUser();
            let isAuthenticated = !['MDOA_STAFF', 'METER_READER', 'OCR_METER_READER'].includes(String(currentUser.accountType)) &&
                                   (!!currentUser.isAD || (!currentUser.isAD && new Date(currentUser.expirationDate) >= new Date()));
            if(!isAuthenticated){
              this.router.navigate(['']);
            }
            return isAuthenticated;
          });
        })
      }
  }
  
}
