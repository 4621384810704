import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ToastrModule } from "ngx-toastr";

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './core/login/login.component';
import { SharedModule } from '@shared/shared.module';
import { HhtModule } from './core/hht/hht.module';


import { AuthGuard } from '@shared/services/guards/auth-guard.service';
import { RoleGuard } from '@app/shared/services/guards/role-guard.service';
import { CanDeactivateGuard } from '@app/shared/services/guards/deactivate-guard.service';
import { AuthInterceptor } from '@shared/services/guards/auth.interceptor';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({ declarations: [
        AppComponent,
        AdminLayoutComponent,
        LoginComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserAnimationsModule,
        RouterModule.forRoot(AppRoutes, {}),
        SidebarModule,
        NavbarModule,
        ToastrModule.forRoot(),
        FooterModule,
        FixedPluginModule,
        SharedModule,
        MatTabsModule,
        HhtModule,
        NgxSpinnerModule], providers: [
        AuthGuard,
        RoleGuard,
        CanDeactivateGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
