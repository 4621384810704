import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { AuthService } from '@shared/services/guards/auth.service';
import { Auth } from 'aws-amplify';
import { take } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { environment } from '@environments/environment';


@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  accountTypes: any = {
    METER_READER: 'METER READER',
    METER_COORDINATOR: 'METER COORDINATOR',
    NON_MECO_OCR_METER_COORDINATOR: 'NON-MECO OCR METER COORDINATOR',
    OCR_METER_READER: 'OCR METER READER',
    MDOA_ADMIN: 'MDOA ADMIN',
    GP_DATA_STATION_OFFICER: 'GP DATA STATION OFFICER / COORDINATOR',
    NONGP_DATA_STATION_OFFICER: 'NON-GP DATA STATION OFFICER',
    MDOA_STAFF: 'MDOA STAFF',
    DMSA: 'DMSA',
    SUPERUSER: 'SUPERUSER',
    OCR_METER_COORDINATOR: 'OCR METER COORDINATOR'
  }

  readingCenterCode: Array<string> = [];
  defaultReadingCenterCode: string;
  selectedReadingCenter: string;
  confirmModalFormData: Array<any> = [];
  lastLogin: string;
  notModifiedEmail: string;
  passwordExpire: string;

  accountType: string = '';

  constructor(private activatedRoute: ActivatedRoute, 
              private hhtApiService: HhtApiService,
              private authService: AuthService,
              private modal: NgbModal,
              private toastr: ToastrService,
              private datePipe: DatePipe)  { }

  ngOnInit(): void {
    Auth.currentUserInfo().then(user =>{ //call assignCurrentUser to establish access restrictions.
      this.notModifiedEmail = String(user['username']);
      this.authService.assignCurrentUser(String(user['username']).replace(environment.adPrefix, '')).then(()=>{
        this.accountType = this.accountTypes[this.authService.getCurrentUser().accountType];
        this.getMyAccount();
        if (this.authService.getCurrentUser().email.indexOf("@meralco.com.ph") >= 1){
          this.passwordExpire = "";
        }
        else {
          
          var creationDate = this.authService.getCurrentUser().creationDate;
          var expirationDate = this.authService.getCurrentUser().expirationDate
          var displayExpirationDate = this.forceExpirationDateOnNewRegistration(creationDate, expirationDate)
          console.log(creationDate)
          console.log(expirationDate)
          console.log(displayExpirationDate)
          var x = displayExpirationDate.split('-');
 
          this.passwordExpire = "Password will expire on " + x[1] + '/' + x[2] + '/' +x[0];
        }
      });
    });
  }

  format(inputDate) {
    let date, month, year;
  
    date = inputDate.getDate();
    month = inputDate.getMonth();
    year = inputDate.getFullYear();
  
      date = date
          .toString()
          .padStart(2, '0');
  
      month = month
          .toString()
          .padStart(2, '0');
  
    return `${month}/${date}/${year}`;
  }

  getMyAccount() {
    this.hhtApiService.getReaderCodesbyEmail(this.authService.getCurrentUser().email).subscribe((res: any[])=>{
      const readingCenterCode = res['body']['results'][0]['readingCenterCode'].split('|');
      this.defaultReadingCenterCode = res['body']['results'][0]['defaultReadingCenterCode'];
      if(!!this.defaultReadingCenterCode){ // if default reading center code is not empty, then splice the index of the current default reading center to the RCC list.
        const defaultReadingCenterCodeIndex = readingCenterCode.indexOf(this.defaultReadingCenterCode);
        readingCenterCode.splice(defaultReadingCenterCodeIndex, 1);
      }
      this.readingCenterCode = readingCenterCode;
    });
    this.hhtApiService.getUserLoginInfo(this.notModifiedEmail).subscribe((res: any[])=>{
      const data = res['body']['Data'];
      const dataLength = data.length;
      if (dataLength > 0) {
        const timeStamp = `${data[dataLength - 1]['last_login_date']} ${data[dataLength - 1]['last_login_time']}`;
        this.lastLogin = this.datePipe.transform(timeStamp, 'MMMM d, y h:mm a');
      }
    });
  }

  confirmModal(modalContent) {
    this.modal.dismissAll();
    this.confirmModalFormData = [
      { 'key': 'New Reading Center', 'value': this.selectedReadingCenter }
    ];
    const config = {
      windowClass: 'min-height-474',
    };
    this.modal.open(modalContent, config);
  }

  saveMyAccount() {
    const requestPayload = {
      email: this.authService.getCurrentUser().email,
      readingCenterCode: this.selectedReadingCenter
    };
    this.hhtApiService.saveReadingCenterByEmail(requestPayload)
                      .pipe(take(1))
                      .subscribe(res => {
                        console.log('Result', res);
                        this.modal.dismissAll();
                        this.selectedReadingCenter = null;
                        this.getMyAccount();
                        this.toastr.success('Assigned Reading Center was successfully updated', 'Successfully Updated', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
                      },
                      error=>{
                        console.error('Error', error);
                        this.modal.dismissAll();
                        this.toastr.success(error['message'], 'Unsaved Changes', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
                      });
  }

  cancelChangeRCC(){
    this.toastr.success('Assigned Reading Center was not changed', 'Unsaved Changes', {closeButton: true, progressBar:true, timeOut: 10000, extendedTimeOut: 10000 });
    this.modal.dismissAll()
  }

  forceExpirationDateOnNewRegistration(creationDate, expirationDate){
    //add 2 days in expiration date for viewing on new accounts
    //for accounts with expiration date = creation date - 1

    let dateCreationDate: Date = new Date(creationDate);
    let dateExpirationDate: Date = new Date(expirationDate);
    let timeInMilisec: number = dateCreationDate.getTime() - dateExpirationDate.getTime();
    let daysBetweenDates: number = Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24));
    
    console.log('daysBetweenDates',daysBetweenDates);

    //daysBetweenDates will be positive if creationDate > expiration Date
    if(daysBetweenDates == 1){
      let expirationDatePlusTwo = new Date(dateExpirationDate.setDate(dateExpirationDate.getDate() + 2));
      return expirationDatePlusTwo.toISOString().slice(0, 10);
    }
    else{
      return expirationDate;
      
    }
    
    
  }

}

  

  